/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Button from "../utility/Button";
import { getStorage, removeStorage, setStorage } from "../../../helpers/utils";

const logo = require('../../assets/alex-logo-cropped.png');

export default function Greeting({ routerHandler, onBoardingPageChange }) {

    const [userName, setUsername] = useState('')
    const [userData, setUserdata] = useState({})

    useEffect(() => {
        let userData = getStorage('currentLoggedInUser');

        if (userData) {
            setUserdata(userData)
            setUsername(userData.User_info.displayName)
        } else {
            // back to login condition
        }
    }, [])

    const pageChange = (value) => {
        onBoardingPageChange(value);
    }

    const routerChange = (value) => {
        routerHandler(value);
    }

    return (
        <div className="greeting row mx-0 px-4 h-100 justify-center text-center">
            <div className="col-12 p-0 my-auto">
                <img src={logo} alt="Logo" className="inline-block mb-4" />
                <h3 className="font-abadimt-probold font-lg mb-4"><b className="text-default-blue font-bold">Hello {userName ? userName : ''}, I'm</b> <span className="text-blue">ALEX!</span></h3>
                <p className="font-abadimt-light font-md mb-4 text-default-blue bodytext">
                    I am your AI-powered Learning Experience Design companion. <br /><br />
                    I'm here to assist and empower you in crafting exceptional course outlines. While I bring AI magic to the table, creating a course outline is a collaborative journey where your insights and critical thinking play a vital role. <br /><br />
                    Are you ready to embark on this journey with me?
                </p>
                <Button className="action text-default-blue font-abadimt-prolight font-md" text="I am Ready!" onClick={() => pageChange("persona")} />
            </div>
            <Footer routerHandler={routerChange} className="text-center" />
        </div>

    )
}