/* eslint-disable react/jsx-key */
/* eslint-disable prettier/prettier */
import React, {useEffect, useRef, useState} from "react";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import {getStorage} from "../../../helpers/utils";
import axios from "axios";

import {
    ALEX_CONVERSATIONAL_CHAT_CODE,
    ALEX_REFINEMENT_INQUIRY_CODE,
    BROWSE_PROMPT_CODE,
    DEFAULT_AZURE_FUNCTION_URL,
    FAVORITE_PROMPT_CODE,
    PROMPT_CATEGORY_CODE
} from '../../../environment'


export default function ChatStepTwo(props) {
    const {
        goToGuidedPage,
        handleClick,
        onChangeisNavigationComingFromCourseDesign,
        isNavigationComingFromCourseDesign,
        isTE21,
        isLearningResource,
        is5C,
        onChangeTypeGeneration,
        typeGeneration,
        questionToBeAsked,
        onChangeAnswerResult,
        chatResponse,
        setIsiKonten,
        textingFunc,
        uuid,
        onResetSession
        // highlightedText,
        // onSetIsTE21,
        // onSetIsLearningResource,
        // onSetIs5C,
        // submitChat,
        // isiKonten,
        // isiGuided1,
    } = props;

    // const [response, setResponse] = useState(null);
    // const [checkedT2, setCheckedT2] = useState(false);
    // const [checked5C, setChecked5C] = useState(false);
    // const [checkedLR, setCheckedLR] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [activeKey, setActiveKey] = useState(null);
    const [resetModal, setResetModal] = useState(false);
    const [rawResponse, setRawResponse] = useState(null);
    const [appliedOption1, setAppliedOption1] = useState(false);
    const [appliedOption2, setAppliedOption2] = useState(false);
    const [appliedOption3, setAppliedOption3] = useState(false);
    const [buttonStatus, setButtonStatus] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [promptFE, setPromptFE] = useState("");
    const [compilationQuestionAnswer, setCompilationQuestionAnswer] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [addtoFav, setAddtoFav] = useState(false)

    // PROMPT CATEGORY
    const [categoryOption, categoryOptionSet] = useState([]);
    const [choosenCategory, choosenCategorySet] = useState("");
    const [promptLibData, PromptLibDataSet] = useState([]);
    const [highlight, setHighlight] = useState(0);
    const [highlightedText, setHighlightedText] = useState("");
    const scrollContainerRef = useRef(null);
    const [isFavoriteActive, isFavoriteActiveSet] = useState(false);
    const [promptFavoriteData, promptFavoriteDataSet] = useState([]);
    const [status1to2, setStatus1to2] = useState(true)
    const [favorites, setFavorites] = useState([]);
    const [favoritedPrompts, favoritedPromptSet] = useState([]);
    const [wordContent, setWordContent] = useState("");

    const [displayHighlightedText, setDisplayHighlightedText] = useState("")

    const [isWaitingResponse, setIsWaitingResponse] = useState(false)

    // PROMPT CATEGORY END

    // const onChangeSetIsTE21 = () => {
    //     onSetIsTE21(!isTE21);
    // };

    // const onChangeIsLearningResource = () => {
    //     onSetIsLearningResource(!isLearningResource);
    // };

    // const onChangeIs5C = () => {
    //     onSetIs5C(!is5C);
    // };

    const handleItemClick = (eventKey) => {
        setActiveKey(eventKey);
    };

    const handleCloseButtonClick = () => {
        setActiveKey(null);
    };

    const handleClose = () => {
        setIsShowModal(false);
    };

    function SpanWithTooltip({id, children, tooltip}) {
        return (
            <OverlayTrigger
                overlay={
                    <Tooltip className="my-custom-tooltip-2 w-100" style={{maxWidth: "97.5%"}} id={id}>
                        {tooltip}
                    </Tooltip>
                }
                placement="top"
            >
                <span className={""}>{children}</span>
            </OverlayTrigger>
        );
    }

    function selectionChangedHandler() {
        try {
            Word.run(async (context) => {
                // Get the current selection in the Word document
                const range = context.document.getSelection();

                // Load the text of the selection
                range.load("text");

                // Sync the document state and get the selection text
                await context.sync();

                // setHighlightedText(range.text);
                if ((range.text).length > 50) {
                    setHighlightedText(range.text);
                    setDisplayHighlightedText(range.text.substring(0, 50).concat('...'));
                } else {
                    setHighlightedText(range.text);
                    setDisplayHighlightedText(range.text);
                }

            });
        } catch (error) {
            console.error(error);
            return error
        }
    }

    useEffect(() => {
        Office.onReady(() => {
            Word.run(async (context) => {
                const body = context.document.body.paragraphs;
                body.load('text');
                await context.sync();
                // const documentText = (body.text).replace(/[\u0000-\u001F\u007F-\u009F]/g, "");
                let documentText = "";
                body?.items?.map((data) => {
                    if (data.text !== "") {
                        documentText += (data.text).replace(/[\u0000-\u001F\u007F-\u009F]/g, "") + "\n" 
                    }

                })
                setWordContent(documentText)
            });
            Office.context.document.addHandlerAsync(
                Office.EventType.DocumentSelectionChanged,
                selectionChangedHandler
            );
        }).catch(err => console.error(err));
    })


    //   PROMPT LIBRARY
    useEffect(() => {
        const tokenFinal = getStorage('tokenFinal')
        // GET CATEGORY
        axios
            .get(
                DEFAULT_AZURE_FUNCTION_URL+"/api/CMS_Prompt_Category?code="+PROMPT_CATEGORY_CODE,
                {
                    headers: {
                        Authorization: "Bearer " + tokenFinal
                    }
                }
            )
            .then((res) => {
                let tmpData = [];
                let response = res.data;

                tmpData.push({ value: "favourite", label: "Favourite Prompts" })

                response.map((val) => {
                    tmpData.push({value: val.Category_id, label: val.Category});
                });

                categoryOptionSet(tmpData);
            })
            .catch((err) => console.log(err));

        //   GET ALL PROMPT LIBRARY DATA
        axios
            .get(
                DEFAULT_AZURE_FUNCTION_URL+"/api/CMS_Browse_Prompt?code="+BROWSE_PROMPT_CODE,
                {
                    headers: {
                        Authorization: "Bearer " + tokenFinal
                    }
                }
            )
            .then((res) => {
                let data = res.data.prompt_list;

                if (data.length > 0) {
                    PromptLibDataSet(data);
                } else {
                    PromptLibDataSet([]);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        const tokenFinal = getStorage('tokenFinal')
        axios
            .get(
                `${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_Browse_Prompt?code=${BROWSE_PROMPT_CODE}&category_ids=${choosenCategory}`,
                {
                    headers: {
                        Authorization: "Bearer " + tokenFinal
                    }
                }
            )
            .then((res) => {
                let data = res.data.prompt_list;

                if (data.length > 0) {
                    PromptLibDataSet(data);
                } else {
                    PromptLibDataSet([]);
                }
            })
            .catch((err) => console.log(err));
    }, [choosenCategory])

    useEffect(() => {
        const tokenFinal = getStorage('tokenFinal')
        const userData = getStorage("currentLoggedInUser");

        if (isFavoriteActive) {
            const userEmail = userData.User_info.mail;

            axios.get(`${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_Favourite_prompt?code=${FAVORITE_PROMPT_CODE}&user_email=${userEmail}`,
                {
                    headers: {
                        Authorization: "Bearer " + tokenFinal
                    }
                }).then((res) => {
                let tmpFavData = [];

                res.data.map((val) => {
                    tmpFavData.push({id: val.favourite_prompt_id, prompt: val.prompt});
                })

                promptFavoriteDataSet(tmpFavData);
            }).catch(err => console.log(err))
        } else {
            promptFavoriteDataSet([]);

            if (choosenCategory.length == 0) {
                axios
                    .get(
                        `${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_Browse_Prompt?code=${BROWSE_PROMPT_CODE}&category_ids=${choosenCategory}`,
                        {
                            headers: {
                                Authorization: "Bearer " + tokenFinal
                            }
                        }
                    )
                    .then((res) => {
                        let data = res.data.prompt_list;

                        if (data.length > 0) {
                            PromptLibDataSet(data);
                        } else {
                            PromptLibDataSet([]);
                        }
                    })
                    .catch((err) => console.log(err));
            }
        }
    }, [isFavoriteActive])

    //   FUNCTIONS
    const onChoosenCategoryChanged = (value) => {
        let stringParse: string = "";

        if (value.length > 0) {
            let countFavorite = 0;

            value.map((val, idx) => {
                if (Number.isInteger(val.value)) {
                    stringParse += (stringParse.length > 0 ? ',' + val.value.toString() : val.value.toString());
                } else {
                    countFavorite += 1;
                }

                if (idx == value.length - 1) {
                    if (countFavorite > 0) isFavoriteActiveSet(true);
                    else isFavoriteActiveSet(false);
                }
            });

            choosenCategorySet(stringParse);
        } else {
            choosenCategorySet("");
            isFavoriteActiveSet(false);
        }
    };

    const onUsePrompt = () => {
        setPromptFE("");
        setPrompt("");
        setIsShowModal(false);

        if (isFavoriteActive) {
            setPromptFE(promptFavoriteData[highlight-100].prompt);
            setPrompt(promptFavoriteData[highlight-100].prompt);
        } else {
            setPromptFE(promptLibData[highlight].Prompt);
            setPrompt(promptLibData[highlight].Prompt);
        }

        // Reset
        choosenCategorySet("");
        setHighlight(-1);
    }
    //   PROMPT LIBRARY END

   

    const handleSubmitChat = () => {
        // setPromptFE("");


        if (prompt) {
            setIsLoading(true);

            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, prompt]);

            const ENDPOINT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_Conversational_Chat"
            const ENDPOINT_HIGHLIGHT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_Refinement_Inquiry"
            const apiCode = ALEX_CONVERSATIONAL_CHAT_CODE;

            const isiKonten = prompt;
            const currentData = getStorage("currentLoggedInUser");
            const persona = currentData?.Persona;
            const tokenFinal = getStorage("tokenFinal");


            const requestPayload = {
                word_content: wordContent,
                prompt: isiKonten,
                custom_instruction: null,
                session_id: uuid,
                user_email: currentData?.User_info?.mail,
                name: currentData?.User_info?.displayName, // Include the name you want in the request
                activity: "Ask a question"
            };

            setIsWaitingResponse(true)
            if (highlightedText == "") {
                axios
                    .post(ENDPOINT_URL + "?code=" + apiCode, requestPayload, {
                        headers: {
                            "x-functions-key": apiCode,
                            "Authorization": "Bearer " + tokenFinal
                        },
                    })
                    .then((resp) => {
                        setRawResponse(resp);
                        setPrompt("");
                        setPromptFE("");
                        setIsWaitingResponse(false)
                    })
                    .catch((error) => {

                        if (error?.response?.data?.controversial_content) {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                error: error?.response?.data?.controversial_content
                            }]]);

                        } else if (error?.response?.data?.token_error) {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                // error: error?.response?.data?.token_error
                                error: "Uh-oh! There's too much content in the Word document for me to handle. Try reducing the content to maximum 40 pages and/or 5000 words and give it another go."
                            }]]);

                        } else {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                error: "Oops! Seems like ALEX is a little busy… Please submit your question again."
                            }]]);

                        }

                        setButtonStatus(true)
                        setIsLoading(false)
                        setStatus1to2(false)
                        setIsWaitingResponse(false)
                        console.error(error)
                    });
            } else {
                axios
                    .post(ENDPOINT_HIGHLIGHT_URL + "?code=" + ALEX_REFINEMENT_INQUIRY_CODE, {
                        word_content: wordContent,
                        highlighted_content: highlightedText,
                        prompt: isiKonten,
                        user_email: currentData?.User_info?.mail,
                        session_id: uuid,
                        activity: "Ask a question"
                    }, {
                        headers: {
                            "x-functions-key": ALEX_REFINEMENT_INQUIRY_CODE,
                            "Authorization": "Bearer " + tokenFinal
                        },
                    })
                    .then((resp) => {
                        setRawResponse(resp);
                        setPrompt("");
                        setPromptFE("");
                        setIsWaitingResponse(false)
                        // setHighlightedText("")
                        // setDisplayHighlightedText("")
                    })
                    .catch((error) => {
                        if (error?.response?.data?.controversial_content) {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                error: error?.response?.data?.controversial_content
                            }]]);

                        } else if (error?.response?.data?.token_error) {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                // error: error?.response?.data?.token_error
                                error: "Uh-oh! There's too much content in the Word document for me to handle. Try reducing the content to maximum 40 pages and/or 5000 words and give it another go."
                            }]]);

                        } else {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                error: "Oops! Seems like ALEX is a little busy… Please submit your question again."
                            }]]);

                        }

                        setButtonStatus(true)
                        setIsLoading(false)
                        setIsWaitingResponse(false)
                        console.error(error)
                    });
            }

        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        if(prompt) {
            setIsLoading(true);

            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, prompt]);

            const ENDPOINT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_Conversational_Chat"
            const ENDPOINT_HIGHLIGHT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_Refinement_Inquiry"
            const apiCode = ALEX_CONVERSATIONAL_CHAT_CODE;

            const isiKonten = prompt;
            const currentData = getStorage("currentLoggedInUser");
            const persona = currentData?.Persona;
            const tokenFinal = getStorage("tokenFinal");


            const requestPayload = {
                word_content: wordContent,
                prompt: isiKonten,
                custom_instruction: null,
                session_id: uuid,
                user_email: currentData?.User_info?.mail,
                name: currentData?.User_info?.displayName, // Include the name you want in the request
                activity: "Ask a question"
            };
            setIsWaitingResponse(true)
            if (highlightedText == "") {
                axios
                    .post(ENDPOINT_URL + "?code=" + apiCode, requestPayload, {
                        headers: {
                            "x-functions-key": apiCode,
                            "Authorization": "Bearer " + tokenFinal
                        },
                    })
                    .then((resp) => {
                        setRawResponse(resp);
                        setPrompt("");
                        setPromptFE("");
                        setIsWaitingResponse(false)
                    })
                    .catch((error) => {

                        if (error?.response?.data?.controversial_content) {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                error: error?.response?.data?.controversial_content
                            }]]);

                        } else if (error?.response?.data?.token_error) {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                // error: error?.response?.data?.token_error
                                error: "Uh-oh! There's too much content in the Word document for me to handle. Try reducing the content to maximum 40 pages and/or 5000 words and give it another go."
                            }]]);

                        } else {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                error: "Oops! Seems like ALEX is a little busy… Please submit your question again."
                            }]]);

                        }

                        setButtonStatus(true)
                        setIsLoading(false)
                        setStatus1to2(false)
                        setIsWaitingResponse(false)
                        console.error(error)
                    });
            } else {
                axios
                    .post(ENDPOINT_HIGHLIGHT_URL + "?code=" + ALEX_REFINEMENT_INQUIRY_CODE, {
                        word_content: wordContent,
                        highlighted_content: highlightedText,
                        prompt: isiKonten,
                        user_email: currentData?.User_info?.mail,
                        session_id: uuid,
                        activity: "Ask a question"
                    }, {
                        headers: {
                            "x-functions-key": ALEX_REFINEMENT_INQUIRY_CODE,
                            "Authorization": "Bearer " + tokenFinal
                        },
                    })
                    .then((resp) => {
                        setRawResponse(resp);
                        setPrompt("");
                        setPromptFE("");
                        setIsWaitingResponse(false)
                        // setHighlightedText("")
                        // setDisplayHighlightedText("")
                    })
                    .catch((error) => {
                        if (error?.response?.data?.controversial_content) {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                error: error?.response?.data?.controversial_content
                            }]]);

                        } else if (error?.response?.data?.token_error) {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                // error: error?.response?.data?.token_error
                                error: "Uh-oh! There's too much content in the Word document for me to handle. Try reducing the content to maximum 40 pages and/or 5000 words and give it another go."
                            }]]);

                        } else {
                            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, [{
                                error: "Oops! Seems like ALEX is a little busy… Please submit your question again."
                            }]]);

                        }

                        setButtonStatus(true)
                        setIsLoading(false)
                        setIsWaitingResponse(false)
                        console.error(error)
                    });
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    const handleChangeKonten = (e) => {
        let text = e.target.value;
        setPrompt(text);
        setPromptFE(text);
        // setIsiKonten(text)
    };

    useEffect(() => {
        if (rawResponse) {

            let Responses = [];
            if (highlightedText == "") {
                Responses = [{
                    response: rawResponse?.data?.Responses?.Responses,
                    references: rawResponse?.data?.Responses?.References
                }]

            } else {
                Responses = [{
                    response: rawResponse?.data?.Responses?.Responses,
                    references: []
                }]

            }

            setCompilationQuestionAnswer((compilationQuestionAnswer) => [...compilationQuestionAnswer, Responses]);

            setIsLoading(false);
            // setResponse(response)
        }
    }, [rawResponse]);

    useEffect(() => {
    }, [isFavoriteActive])

    useEffect(() => {
        if (isLoading) setButtonStatus(false);
        else setButtonStatus(true);
    }, [isLoading]);

    useEffect(() => {
        const currentData = getStorage("currentLoggedInUser");
        const persona = currentData?.Persona;

        if (persona === "MOE") {
            setAppliedOption1(true)
            setAppliedOption2(true)
            setAppliedOption3(true)
        }
    }, []);

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    }, [compilationQuestionAnswer]);

    const addPromptToFavorite = async (promptText) => {
        let isFavorited = favoritedPrompts.some(item => item.prompt === promptText);

        if (!isFavorited) {

            const userData = getStorage("currentLoggedInUser");

            let data = {
                user_email: userData.User_info.mail,
                prompt: promptText
            }

            const tokenFinal = getStorage('tokenFinal')

            return await axios.post(DEFAULT_AZURE_FUNCTION_URL+"/api/CMS_Favourite_prompt?code="+FAVORITE_PROMPT_CODE, data, {
                headers: {
                    "Authorization": "Bearer " + tokenFinal
                }
            }).then((res) => {
                if (res.status && res.status != 200) {
                    handleClick('auth-login');
                } else {
                    favoritedPromptSet([...favoritedPrompts, res.data]);
                }
            }).catch(err => console.log(err))
        }
    }

    const toggleFavoritedPrompt = async (promptText) => {
        let isFavorited = favoritedPrompts.some(item => item.prompt === promptText);
        let tmpArray = favoritedPrompts;

        if (isFavorited) {
            tmpArray = await arrayRemove(tmpArray, promptText);
            favoritedPromptSet(tmpArray);
        } else {
            addPromptToFavorite(promptText);
        }
    }

    const arrayRemove = async (arr, value) => {
        const index = arr.findIndex(item => item.prompt === value);
        const tokenFinal = getStorage('tokenFinal')

        axios.delete(DEFAULT_AZURE_FUNCTION_URL+"/api/CMS_Favourite_prompt?code="+FAVORITE_PROMPT_CODE, {
            headers: {
                "Authorization": "Bearer " + tokenFinal
            },
            data: {
                favourite_prompt_id: arr[index].favourite_prompt_id
            }
        }).then(() => {
            return "success"
        })
            .catch(err => console.log(err))

        return await arr.filter(item => item.prompt !== value);
    }

    useEffect(() => {
    }, [favoritedPrompts])

    const ShowedPromptCount = () => {
        if (isFavoriteActive && choosenCategory.length > 0) {
            return (
                <b>Showing {promptFavoriteData.length + promptLibData.length} results</b>
            )
        } else if (isFavoriteActive && choosenCategory.length < 1) {
            return (
                <b>Showing {promptFavoriteData.length} results</b>
            )
        } else if (!isFavoriteActive && choosenCategory.length > 0) {
            return (
                <b>Showing {promptLibData.length} results</b>
            )
        } else {
            return (
                <b>Showing {promptLibData.length} results</b>
            )
        }
    }

    const deleteFavoritePrompt = (promptId) => {
        const tokenFinal = getStorage('tokenFinal')
        const dataToDelete = {
            favourite_prompt_id: promptId
        };

        axios
            .delete(DEFAULT_AZURE_FUNCTION_URL+"/api/CMS_Favourite_prompt?code="+FAVORITE_PROMPT_CODE, {
                headers: {
                    "Authorization": "Bearer" + tokenFinal
                },
                data: dataToDelete
            })
            .then(() => {

                const userData = getStorage("currentLoggedInUser");
                const userEmail = userData.User_info.mail;
                const tokenFinal = getStorage('tokenFinal')

                axios.get(`${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_Favourite_prompt?code=${FAVORITE_PROMPT_CODE}&user_email=${userEmail}`, {
                    headers: {
                        "Authorization": "Bearer " + tokenFinal
                    }
                }).then((res) => {
                    let tmpFavData = [];

                    res.data.map((val) => {
                        tmpFavData.push({id: val.favourite_prompt_id, prompt: val.prompt});
                    })

                    promptFavoriteDataSet(tmpFavData);
                }).catch(err => console.log(err))

            })
            .catch((error) => {
                console.error(error);
            });
    }

    const PromptLibList = () => {
        if (isFavoriteActive && choosenCategory.length > 0) {
            return (
                <>
                    {
                        promptFavoriteData.map((val, idx) => {
                            return (
                                <div
                                    key={idx + 100}
                                    className={`card mx-0 default-card ${highlight === idx + 100 ? "active" : ""}`}
                                    onClick={() => setHighlight(idx + 100)}
                                >
                                    <div className="card-body">
                                        <p className="font-abadimt-pronormal">
                                            <div className="row mx-0 justify-content-start align-items-start">
                                                <div className="col-auto p-0 me-1">
                                                    <span
                                                        className="font-abadimt-pronormal inline-block" style={{
                                                        color: "#A1A1A1",
                                                        fontWeight: "normal"
                                                    }}>Related category: </span>
                                                </div>
                                                <div className="col p-0 pe-1">
                                                    <div
                                                        className="row mx-0 justify-content-start align-items-start font-abadimt-pronormal">
                                                        <div className="col-auto p-0 pe-1">
                                                            <span className="font-highlight"
                                                                  style={{color: "#3E6CC7", fontWeight: "normal"}}>
                                                                Favourite Prompts
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                        <p className="font-abadimt-extralight"
                                           style={{color: "#001134", fontWeight: "lighter"}}>
                                            {val.prompt}
                                        </p>
                                        <div className="text-end">
                                            <button className="btn bg-transparent font-md align-items-center"
                                                    style={{color: "#FF1635"}} onClick={() => {
                                                deleteFavoritePrompt(val.id)
                                            }}>
                                                <img src={require("../../assets/new-version/ion-trash.png")}
                                                     alt="Remove" width={14} className="d-inline-block"
                                                     style={{verticalAlign: "middle"}}/>
                                                <span className={`d-inline-block ms-1`} style={{
                                                    fontSize: "16px",
                                                    verticalAlign: "middle"
                                                }}>Remove prompt</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        promptLibData.map((val, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={`card mx-0 default-card ${highlight === idx ? "active" : ""}`}
                                    onClick={() => setHighlight(idx)}
                                >
                                    <div className="card-body">
                                        <p className="font-abadimt-pronormal">
                                            <div className="row mx-0 justify-content-start align-items-start">
                                                <div className="col-auto p-0 me-1">
                                                    <span
                                                        className="font-abadimt-pronormal inline-block" style={{
                                                        color: "#A1A1A1",
                                                        fontWeight: "normal"
                                                    }}>Related category: </span>
                                                </div>
                                                <div className="col p-0 pe-1">
                                                    <div
                                                        className="row mx-0 justify-content-start align-items-start font-abadimt-pronormal">
                                                        {
                                                            val.Category.length > 0 ? val.Category.map((val2, idx2) => {
                                                                return (
                                                                    <div className="col-auto p-0 pe-1"
                                                                         key={`${val2}-${idx2}`} style={{
                                                                        color: "3E6CC7",
                                                                        fontWeight: "normal"
                                                                    }}>
                                                                        <span
                                                                            className="font-highlight">{val2.Category}{idx2 + 1 != val.Category.length ? ',' : ''}</span>
                                                                    </div>
                                                                )
                                                            }) : '-'
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                        <p className="font-abadimt-extralight"
                                           style={{color: "#001134", fontWeight: "lighter"}}>
                                            {val.Prompt}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            )
        } else if (isFavoriteActive && choosenCategory.length < 1) {
            return (
                <>
                    {
                        promptFavoriteData.map((val, idx) => {
                            return (
                                <div
                                    key={idx + 100}
                                    className={`card mx-0 default-card ${highlight === idx + 100 ? "active" : ""}`}
                                    onClick={() => setHighlight(idx + 100)}
                                >
                                    <div className="card-body">
                                        <p className="font-abadimt-pronormal">
                                            <div className="row mx-0 justify-content-start align-items-start">
                                                <div className="col-auto p-0 me-1">
                                                    <span
                                                        className="font-abadimt-pronormal inline-block" style={{
                                                        color: "#A1A1A1",
                                                        fontWeight: "normal"
                                                    }}>Related category: </span>
                                                </div>
                                                <div className="col p-0 pe-1">
                                                    <div
                                                        className="row mx-0 justify-content-start align-items-start font-abadimt-pronormal">
                                                        <div className="col-auto p-0 pe-1">
                                                            <span className="font-highlight"
                                                                  style={{color: "#3E6CC7", fontWeight: "normal"}}>
                                                                Favourite Prompts
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                        <p className="font-abadimt-extralight"
                                           style={{color: "#001134", fontWeight: "lighter"}}>
                                            {val.prompt}
                                        </p>
                                        <div className="text-end">
                                            <button className="btn bg-transparent font-md align-items-center"
                                                    style={{color: "#FF1635"}} onClick={() => {
                                                deleteFavoritePrompt(val.id)
                                            }}>
                                                <img src={require("../../assets/new-version/ion-trash.png")}
                                                     alt="Remove" width={14} className="d-inline-block"
                                                     style={{verticalAlign: "middle"}}/>
                                                <span className={`d-inline-block ms-1`} style={{
                                                    fontSize: "16px",
                                                    verticalAlign: "middle"
                                                }}>Remove prompt</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            )
        } else if (!isFavoriteActive && choosenCategory.length > 0) {
            return (
                <>
                    {
                        promptLibData.map((val, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={`card mx-0 default-card ${highlight === idx ? "active" : ""}`}
                                    onClick={() => setHighlight(idx)}
                                >
                                    <div className="card-body">
                                        <p className="font-abadimt-pronormal">
                                            <div className="row mx-0 justify-content-start align-items-start">
                                                <div className="col-auto p-0 me-1">
                                                    <span
                                                        className="font-abadimt-pronormal inline-block" style={{
                                                        color: "#A1A1A1",
                                                        fontWeight: "normal"
                                                    }}>Related category: </span>
                                                </div>
                                                <div className="col p-0 pe-1">
                                                    <div
                                                        className="row mx-0 justify-content-start align-items-start font-abadimt-pronormal">
                                                        {
                                                            val.Category.length > 0 ? val.Category.map((val2, idx2) => {
                                                                return (
                                                                    <div className="col-auto p-0 pe-1"
                                                                         key={`${val2}-${idx2}`} style={{
                                                                        color: "3E6CC7",
                                                                        fontWeight: "normal"
                                                                    }}>
                                                                        <span
                                                                            className="font-highlight">{val2.Category}{idx2 + 1 != val.Category.length ? ',' : ''}</span>
                                                                    </div>
                                                                )
                                                            }) : '-'
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                        <p className="font-abadimt-extralight"
                                           style={{color: "#001134", fontWeight: "lighter"}}>
                                            {val.Prompt}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            )
        } else {
            return (
                <>
                    {
                        promptLibData.map((val, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className={`card mx-0 default-card ${highlight === idx ? "active" : ""}`}
                                    onClick={() => setHighlight(idx)}
                                >
                                    <div className="card-body">
                                        <p className="font-abadimt-pronormal">
                                            <div className="row mx-0 justify-content-start align-items-start">
                                                <div className="col-auto p-0 me-1">
                                                    <span
                                                        className="font-abadimt-pronormal inline-block" style={{
                                                        color: "#A1A1A1",
                                                        fontWeight: "normal"
                                                    }}>Related category: </span>
                                                </div>
                                                <div className="col p-0 pe-1">
                                                    <div
                                                        className="row mx-0 justify-content-start align-items-start font-abadimt-pronormal">
                                                        {
                                                            val.Category.length > 0 ? val.Category.map((val2, idx2) => {
                                                                return (
                                                                    <div className="col-auto p-0 pe-1"
                                                                         key={`${val2}-${idx2}`} style={{
                                                                        color: "3E6CC7",
                                                                        fontWeight: "normal"
                                                                    }}>
                                                                        <span
                                                                            className="font-highlight">{val2.Category}{idx2 + 1 != val.Category.length ? ',' : ''}</span>
                                                                    </div>
                                                                )
                                                            }) : '-'
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </p>
                                        <p className="font-abadimt-extralight"
                                           style={{color: "#001134", fontWeight: "lighter"}}>
                                            {val.Prompt}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </>
            )
        }
    }

    useEffect(() => {
    }, [promptFavoriteData])

    return (
        <div className="d-flex flex-column font-abadimt-prolight" style={{minHeight: "85vh"}}>
            <div className={"d-flex justify-content-between"}>
                <h4 className={"text-default-blue"}>Chat session</h4>
                <div
                    className="d-flex align-items-center text-end gap-1 text-default-blue cursor-pointer"
                    onClick={() => setResetModal(true)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path
                            d="M2.76504 0.234856C2.83535 0.305179 2.87484 0.400544 2.87484 0.499981C2.87484 0.599417 2.83535 0.694783 2.76504 0.765106L1.53017 1.99998H6.06242C8.23704 1.99998 9.99992 3.76286 9.99992 5.93748C9.99992 8.11211 8.23704 9.87498 6.06242 9.87498C3.88779 9.87498 2.12492 8.11211 2.12492 5.93748C2.12492 5.83802 2.16443 5.74264 2.23475 5.67232C2.30508 5.60199 2.40046 5.56248 2.49992 5.56248C2.59938 5.56248 2.69476 5.60199 2.76508 5.67232C2.83541 5.74264 2.87492 5.83802 2.87492 5.93748C2.87492 6.56791 3.06186 7.18418 3.41211 7.70836C3.76236 8.23254 4.26018 8.64109 4.84262 8.88235C5.42506 9.1236 6.06596 9.18672 6.68427 9.06373C7.30258 8.94074 7.87054 8.63716 8.31632 8.19138C8.7621 7.7456 9.06568 7.17765 9.18867 6.55933C9.31166 5.94102 9.24854 5.30012 9.00729 4.71768C8.76603 4.13524 8.35748 3.63742 7.8333 3.28717C7.30912 2.93692 6.69285 2.74998 6.06242 2.74998H1.53017L2.76504 3.98486C2.80086 4.01945 2.82943 4.06083 2.84908 4.10658C2.86874 4.15233 2.87908 4.20154 2.87951 4.25133C2.87995 4.30112 2.87046 4.3505 2.8516 4.39659C2.83275 4.44267 2.8049 4.48454 2.76969 4.51975C2.73448 4.55496 2.69261 4.58281 2.64653 4.60166C2.60044 4.62052 2.55106 4.63001 2.50127 4.62957C2.45148 4.62914 2.40227 4.6188 2.35652 4.59914C2.31077 4.57949 2.26939 4.55092 2.23479 4.51511L0.359795 2.64011C0.289493 2.56978 0.25 2.47442 0.25 2.37498C0.25 2.27554 0.289493 2.18018 0.359795 2.10986L2.23479 0.234856C2.30512 0.164554 2.40048 0.125061 2.49992 0.125061C2.59936 0.125061 2.69472 0.164554 2.76504 0.234856Z"
                            fill="#002060"
                        />
                    </svg>
                    <span className="font-abadimt-pronormal">Reset Chat</span>
                </div>
            </div>

            <div className="px-4" style={{minHeight: "55vh", maxHeight: "55vh", overflowY: "scroll"}}
                 ref={scrollContainerRef}>
                <div className="d-flex flex-column gap-3">
                    

                    {compilationQuestionAnswer.map((data, index) => {
                        if (Array.isArray(data)) {
                            return (
                                <div className={`chat-box`} key={index}>
                                    {data.map((data2, index2) => {
                                        if (data2?.hasOwnProperty('error')) {
                                            return (
                                                <div key={index + "_" + index2}>
                                                    <div
                                                        className={"d-flex align-items-center gap-2 mb-2 text-dgray-2"}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="10"
                                                            height="10"
                                                            viewBox="0 0 10 10"
                                                            fill="none"
                                                        >
                                                            <circle cx="5" cy="5" r="5" fill="#00B0F0"/>
                                                        </svg>
                                                        ALEX
                                                    </div>

                                                    <div className={"bg-ltblue rounded text-default-blue p-3 mt-3"}>
                                                        {data2?.error}
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={index + "_" + index2}>
                                                    <div className={"d-flex align-items-center gap-2 mb-2"}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="10"
                                                            height="10"
                                                            viewBox="0 0 10 10"
                                                            fill="none"
                                                        >
                                                            <circle cx="5" cy="5" r="5" fill="#00B0F0"/>
                                                        </svg>
                                                        ALEX
                                                    </div>
                                                    <div className={"bg-ltblue rounded text-dblue p-3"}
                                                         key={'bb_' + index + "_" + index2}>
                                                            {/* {data2.response} */}
    
    
                                                            {data2?.response?.split('\n').map(content => {
                                                                if (content.length > 0) {
                                                                    return <p>{content}</p>
                                                                } else {
                                                                    return ('')
                                                                }
                                                            })}
    
    
                                                    </div>
    
                                                    {data2?.references?.length > 0 ?
                                                        <div className={"bg-ltblue rounded text-dblue p-3 mt-3"}
                                                             key={'reference_header_' + index + "_" + index2}>
                                                            Keen to explore the resources I have referenced to answer your
                                                            question?
                                                            <ul style={{wordWrap:"break-word"}}>
                                                                {data2?.references?.map((reference, index3) => {
                                                                    return (
                                                                        <li key={'reference_' + index + "_" + index2 + "_" + index3}>
                                                                            <a
                                                                                className="font-action-hightlight"
                                                                                href={reference["Blob_Url"]}
                                                                                target="_blank">{reference["File name"] == "" ? reference["Blob_Url"] : reference["File name"]}</a>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                        : <></>}
    
                                                </div>
                                            )

                                        }

                                    })}
                                </div>
                            );
                        } else {
                            return (
                                <>
                                    {data ? (
                                        <div key={'aa_' + index}>
                                            <div className={"d-flex align-items-center text-default-blue gap-2 mb-2"}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="10"
                                                    height="10"
                                                    viewBox="0 0 10 10"
                                                    fill="none"
                                                >
                                                    <circle cx="5" cy="5" r="5" fill="#D95AAE"/>
                                                </svg>
                                                {getStorage("currentLoggedInUser")?.User_info?.displayName}
                                            </div>
                                            <div
                                                className={"position-relative rounded bg-another-gray border-0 p-3 font-abadimt-light text-dblue"} style={{wordWrap:"break-word"}}>
                                                <p>
                                                    {data}
                                                </p>
                                            </div>
                                            <div
                                                className="position-relative rounded bg-another-gray border-0 p-3 font-abadimt-light text-dblue">
                                                <button
                                                    className={`d-flex gap-1 mt-3 btn-add-fav align-items-center ${addtoFav ? "text-dblue" : "grey-another-shade"}`}
                                                    onClick={() => {
                                                        // goToGuidedPage(1);
                                                        // setAddtoFav(!addtoFav)
                                                        toggleFavoritedPrompt(data);
                                                    }}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                                         viewBox="0 0 8 8"
                                                         fill="none">
                                                        <path
                                                            d="M6.15559 7.5C6.10297 7.5002 6.05163 7.4838 6.00887 7.45312L3.99934 5.99624L1.98981 7.45312C1.94688 7.48426 1.89516 7.50094 1.84212 7.50075C1.78909 7.50055 1.73749 7.4835 1.69478 7.45205C1.65208 7.4206 1.62048 7.37638 1.60456 7.32579C1.58864 7.2752 1.58922 7.22086 1.60622 7.17062L2.38997 4.84921L0.358718 3.45624C0.31472 3.42611 0.281512 3.38269 0.263948 3.33233C0.246383 3.28198 0.245382 3.22732 0.261091 3.17636C0.276799 3.12539 0.308394 3.08079 0.351259 3.04906C0.394124 3.01733 0.446012 3.00013 0.499343 2.99999H3.00528L3.76153 0.672651C3.77782 0.622404 3.80961 0.578609 3.85233 0.547548C3.89506 0.516487 3.94652 0.499756 3.99934 0.499756C4.05217 0.499756 4.10363 0.516487 4.14635 0.547548C4.18908 0.578609 4.22086 0.622404 4.23716 0.672651L4.99341 3.00078H7.49934C7.55274 3.00075 7.60474 3.01782 7.64774 3.04949C7.69073 3.08116 7.72245 3.12576 7.73826 3.17677C7.75407 3.22777 7.75313 3.2825 7.73558 3.33293C7.71804 3.38336 7.6848 3.42685 7.64075 3.45703L5.60872 4.84921L6.392 7.17C6.40469 7.20757 6.40826 7.24762 6.40241 7.28685C6.39657 7.32607 6.38147 7.36335 6.35838 7.39559C6.33528 7.42783 6.30485 7.45411 6.26959 7.47226C6.23433 7.49042 6.19525 7.49992 6.15559 7.5Z"
                                                            fill={favoritedPrompts.some(item => item.prompt === data) ? "#001134" : "#D4D4D9"}
                                                        />
                                                    </svg>
                                                    {favoritedPrompts.some(item => item.prompt === data) ? "Added to Favourite" : "Add to Favourite"}
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}
                                </>
                            );
                        }
                    })}

                    {
                        isLoading ? (
                            <div>
                                <div className={"d-flex align-items-center gap-2 mb-2"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"
                                         viewBox="0 0 10 10" fill="none">
                                        <circle cx="5" cy="5" r="5" fill="#00B0F0"/>
                                    </svg>
                                    ALEX
                                </div>

                                <div className={"bg-ltblue rounded text-dblue p-3"}>
                                    I'm thinking <span className="loading-dots">...</span>
                                </div>
                            </div>

                        ) : null
                    }

                </div>
            </div>


            <div className="mx-4 mt-3">
                <div className={"mb-1"}>
                    <div
                        className={`rounded px-3 py-1 bg-another-gray-2 ${displayHighlightedText !== "" ? "text-default-grey" : "text-dgray"}`}
                        style={{fontSize: "13px"}}>
                        {displayHighlightedText !== "" ? displayHighlightedText : "Highlighted text will display here for reference in prompts."}
                    </div>
                </div>

                <div className="card default-card mt-auto">
                    <div className="card-body">
                        <form onSubmit={handleSubmit} className={`${isWaitingResponse ? "disabled": ""}`}>
                            <textarea className={`text-dblue`} rows={5} onChange={handleChangeKonten}
                                    disabled={isWaitingResponse ? true : false}
                                      onKeyDown={handleKeyDown}
                                      value={promptFE}></textarea>

                            <div className="d-flex justify-content-between ">
                                <SpanWithTooltip
                                    tooltip="Access the Prompt Library to explore structured prompts that can guide your questions effectively."
                                    id="tooltip-1"
                                >
                                    <button onClick={() => {isWaitingResponse ? "": setIsShowModal(true)}} className={`btn-polos text-dblue ${isWaitingResponse ? "disabled":""}`}>
                                        Prompt Library
                                    </button>
                                </SpanWithTooltip>
                                <button
                                    type={"submit"}
                                    className={`btn-dblue ${buttonStatus ? "" : "disabled"} rounded-pill py-1 px-3`}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Modal centered show={resetModal} onHide={() => setResetModal(false)} className="need-margin">
                <Modal.Body>
                    <div className="text-center">
                        <h5>
                            <b className="font-abadimt-probold text-default-blue">Reset the Chat?</b>
                        </h5>
                        <p className="font-abadimt-normal text-default-blue font-md font-abadimt-prolight">Hitting that
                            reset chat button will
                            wipe out our current conversations. Confirm the
                            action?</p>

                        <div className="d-flex gap-3 justify-content-center">
                            <div className="col text-end">
                                <button className={"btn-custom-2 rounded-pill font-abadimt-prolight"}
                                        onClick={() => setResetModal(false)}>
                                    Cancel
                                </button>
                            </div>
                            <div className="col text-start" onClick={() => {
                                handleClick('');
                                setCompilationQuestionAnswer([]);
                                onResetSession();
                            }}>
                                <button className={"btn-custom-2 rounded-pill font-abadimt-prolight"}>Reset chat
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={isShowModal} onHide={handleClose} className="modal-bottom">
                <Modal.Body className={"p-3 overflow-y-auto"}
                            style={{maxHeight: "70vh", minHeight: "70vh", minWidth: "90vw"}}>
                    <h5 className="">
                        <b>Prompt Library</b>
                    </h5>

                    <Accordion activeKey={activeKey}>
                        <Accordion.Item eventKey="0" className={"custom-accordion"}>
                            <Accordion.Header
                                onClick={() => handleItemClick("0")}
                                className="custom-link-2 font-abadimt-extralight mb-3"
                            >
                                What is 'Prompt'?
                            </Accordion.Header>
                            <Accordion.Body className={"position-relative mb-3"}>
                                <button className={"btn-polos btn-x"} type={"button"}
                                        onClick={() => handleCloseButtonClick()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 4 4"
                                         fill="none">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M2.36724 1.89787L3.73449 3.2655L3.99999 3.00038L2.63236 1.63275L3.99999 0.2655L3.73486 0L2.36724 1.36763L0.999988 0L0.734863 0.2655L2.10211 1.63275L0.734863 3L0.999988 3.2655L2.36724 1.89787Z"
                                            fill="#7C7C7C"
                                        />
                                    </svg>
                                </button>
                                <p className={"mb-0 font-abadimt-light"}>
                                    A "prompt" is like a question or a request that you give to ALEX to ask it to do
                                    something or give you
                                    some information. It's a way to tell ALEX what you want it to do or what kind of
                                    answer you're looking
                                    for.
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Select
                        closeMenuOnSelect={false}
                        isMulti
                        name="options"
                        options={categoryOption}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(e) => onChoosenCategoryChanged(e)}
                    />

                    <div className={"my-3"}>
                        <ShowedPromptCount/>
                    </div>

                    <div className={"d-flex flex-column gap-3"}>
                        <PromptLibList/>
                    </div>
                </Modal.Body>
                <Modal.Footer className={"justify-content-center"}>
                    <button
                        onClick={() => onUsePrompt()}
                        className={`d-flex gap-2 btn-custom-2 rounded-pill align-items-center justify-content-center`}
                        style={{width: "fit-content"}}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                            <path
                                d="M8.825 0.125H1.175C0.9375 0.125 0.75 0.325 0.75 0.55V5.7125C0.75 5.95 0.9375 6.1375 1.175 6.1375H5.85L7.55 7.875V6.15H8.825C9.0625 6.15 9.25 5.95 9.25 5.725V0.5625C9.25 0.325 9.0625 0.125 8.825 0.125Z"
                                fill="#002060"
                            />
                        </svg>
                        Use Prompt
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
        ;
}
