import React, { useEffect, useState } from "react";
import { HeroListItem } from "./HeroList";
import Progress from "./Progress";
import axios, { Axios } from "axios";

import GuidedStepOne from "./guided-mk2/StepOne";

import LoadingPage from "./LoadingPage";

import { Navbar } from "./Navbar";
import NavCustomInstruction from "./NavCustomInstruction";
import AboutUs from "./AboutUs";
import OnBoarding from "./onboarding/OnBoarding";
import BrowsePrompt from "./prompt-utils/BrowsePrompt";
import FavouritePrompt from "./prompt-utils/FavouritePrompt";
import RefineCourse from "./prompt-utils/RefineCourse";
import PromptResult from "./prompt-utils/PromptResult";
import Login from "./auth/Login";
import { PublicClientApplication } from "@azure/msal-browser";

import { getStorage, removeStorage, setStorage } from "../../helpers/utils";
import Main from "./new-version/Main";
import { dialogFallback } from "../../helpers/fallbackauthdialog";
import Modal from "react-bootstrap/Modal";
import CompleteInstruction from "./CompleteInstruction";
import ChatStepTwo from "./askquestion/ChatStepTwo";
import { v4 as uuidv4 } from "uuid";

import {
    DEFAULT_AZURE_FUNCTION_URL,
    CMS_USER_LIST_CODE,
    CLIENT_ID,
    CLIENT_SECRET, 
    ALEX_ACCESS_TOKEN_CODE,
    STORE_USER_INFO_CODE,
    LOGIN_CHECK_NIE_CODE} from '../../environment'

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
  highlightedText: string;
}

export interface AppState {
  listItems: HeroListItem[];
  isiKonten: String;
  overridePromp: any;
  loading: boolean;
  currentPage: String;
  selectedFile: any;
  isShowModalOptions: boolean;
  showPage: String;
  questionToBeAsked: String;
  isTE21: boolean;
  isLearningResource: boolean;
  isNavigationComingFromCourseDesign: boolean;
  is5C: boolean;
  answerResult: any;
  guidedCoursePage: Number;
  typeGeneration: String;
  isEvaluated: boolean;
  evaluationAnswer: String;
  chatResponse: any;
}

export default function App(props: any) {
  const { title, isOfficeInitialized, highlightedText } = props;

  const [listItems, setListItems] = useState([]);
  const [isiKonten, setIsiKonten] = useState(
    "a 5-week course outline on Food Fiction: A Creative Writing and Storytelling"
  );
  const [overridePromp, setOverridePromp] = useState(
    "Assistant helps the users with their Evolution and History of Automations questions. Be brief in your answers. Answer ONLY with the facts listed in the list of sources below. If there isn't enough information below, say you don't know. Do not generate answers that don't use the sources below. If asking a clarifying question to the user would help, ask the question. Each source has a name followed by colon and the actual information, always include the page number for each fact you use in the response with it's hyperlink. Use square brackets to reference the page number, e.g. [page1.txt]. Don't combine sources, list each source separately, e.g. [page1.txt][page2.pdf]"
  );
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("main");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isShowModalOptions, setIsShowModalOptions] = useState(false);
  const [showPage, setShowPage] = useState("");
  const [questionToBeAsked, setQuestionToBeAsked] = useState("");
  const [isTE21, setIsTE21] = useState(false);
  const [isLearningResource, setIsLearningResource] = useState(false);
  const [is5C, setIs5C] = useState(false);
  const [isNavigationComingFromCourseDesign, setIsNavigationComingFromCourseDesign] = useState(false);
  const [answerResult, setAnswerResult] = useState("");
  const [guidedCoursePage, setGuidedCoursePage] = useState(1);
  const [typeGeneration, setTypeGeneration] = useState("prose");
  const [isEvaluated, setIsEvaluated] = useState(false);
  const [evaluationAnswer, setEvaluationAnswer] = useState("");
  const [chatResponse, setChatResponse] = useState({});
  const [isInChatPage, isInChatPageSet] = useState(false);
  const [previousPage, previousPageSet] = useState("");
  const [previousReference, previousReferenceSet] = useState("");

  const [uuid, setUuid] = useState("");

  const checkAuthToken = async () => {
    setLoading(true)
    const tokenFinal = getStorage("tokenFinal");
    const bearerToken = getStorage("bearerToken");
    const clientId = CLIENT_ID;
    const clientSecret = CLIENT_SECRET;


    if (tokenFinal) {
      return await axios
        .post(
          DEFAULT_AZURE_FUNCTION_URL +
            "/api/CMS_Get_Access_Token?code=" +
            ALEX_ACCESS_TOKEN_CODE +
            "&assertion=" +
            bearerToken,
          null,
          {
            headers: {
              Authorization: bearerToken,
            },
          }
        )
        .then(async (resp) => {
          // setLoading(false)
          if (resp.data !== "") {
            return await axios
              .get("https://graph.microsoft.com/v1.0/me", {
                headers: {
                  Authorization: "Bearer " + tokenFinal,
                },
              })
              .then((res) => {
                setLoading(true)
                if (res.status == 200) {
                  setLoading(false)
                } else {
                  setLoading(false)
                  removeStorage("currentLoggedInUser");
                  removeStorage("Office API client");
                  removeStorage("tokenFinal");
                  removeStorage("bearerToken");
                  setShowPage("auth-login");
                  // return false;
                  return this.setState({ showPage: "auth-login" });
                }
              })
              .catch((err) => {
                console.log(err)
                setLoading(false)
                removeStorage("currentLoggedInUser");
                removeStorage("Office API client");
                removeStorage("tokenFinal");
                removeStorage("bearerToken");
                setShowPage("auth-login");
                // return false;
                return this.setState({ showPage: "auth-login" });
              });
          } else {
            setLoading(false)
            removeStorage("currentLoggedInUser");
            removeStorage("Office API client");
            removeStorage("tokenFinal");
            removeStorage("bearerToken");
            setShowPage("auth-login");
            // return false;
            return this.setState({ showPage: "auth-login" });
          }
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          removeStorage("currentLoggedInUser");
          removeStorage("Office API client");
          removeStorage("tokenFinal");
          removeStorage("bearerToken");
          setShowPage("auth-login");
          // return false;
          return this.setState({ showPage: "auth-login" });
        });
    } else {
      // setShowPage('auth-login')
    }
  };

  useEffect(() => {
    // removeStorage("currentLoggedInUser");
    // removeStorage("Office API client");
    // removeStorage("tokenFinal");

    // removeStorage('currentLoggedInUser')
    // removeStorage('tokenFinal')
    let userData = getStorage("currentLoggedInUser");
    const tokenFinal = getStorage("tokenFinal");
    // const tokenFinal = getStorage('tokenFinal');

    if (userData && userData.hasOwnProperty("User_info") && tokenFinal !== undefined) {
      setLoading(true)
      axios
        .get(
          `${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_User_List?code=${CMS_USER_LIST_CODE}&user_email=${userData.User_info.mail}`,
          {
            headers: {
              Authorization: `Bearer ${tokenFinal}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            userData["is_pass_onboarding"] = res.data.is_pass_onboarding;
            userData["is_consent"] = res.data.is_consent;
            userData["custom_instruction"] = res.data.Custom_instruction;
            setStorage("currentLoggedInUser", userData);
            setLoading(false)
            if (userData && userData.is_pass_onboarding) setShowPage("");
            else if (userData && !userData.is_pass_onboarding) setShowPage("onboarding");
            else setShowPage("auth-login");
          }
        })
        .catch((err) =>{
          setLoading(false)
          console.log(err)}
        );
    } else {
      setLoading(false)
      setShowPage("auth-login");
    }

    // setUuid(Math.floor(Math.random() * 899999 + 100000).toString());
    setUuid(uuidv4());

    const handleStorageChange = () => {
      let userData = getStorage("currentLoggedInUser");

      if (userData && tokenFinal) {
        let defaultUserData = userData;

        if (!userData.is_pass_onboarding) {
          defaultUserData.Persona = "NIE";
          defaultUserData.Custom_instruction = "";
          defaultUserData.is_pass_onboarding = false;
        }

        setStorage("currentLoggedInUser", defaultUserData);
      }

      if (userData && userData.is_pass_onboarding && tokenFinal) setShowPage("");
      else if (userData && !userData.is_pass_onboarding && tokenFinal) setShowPage("onboarding");
      else setShowPage("auth-login");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    let userData = getStorage("currentLoggedInUser");
    const tokenFinal = getStorage("tokenFinal");


    if (userData) {
      checkAuthToken();
    }

    if (userData && userData.hasOwnProperty("User_info") && tokenFinal) {
      setLoading(true)
      axios
        .get(
          `${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_User_List?code=${CMS_USER_LIST_CODE}&user_email=${userData.User_info.mail}`,
          {
            headers: {
              Authorization: `Bearer ${tokenFinal}`,
            },
          }
        )
        .then((res) => {
          setLoading(false)
          if (res.status == 200) {
            userData["is_pass_onboarding"] = res.data.is_pass_onboarding;
            userData["is_consent"] = res.data.is_consent;
            userData["custom_instruction"] = res.data.Custom_instruction;
            setStorage("currentLoggedInUser", userData);


            if (userData && !userData.is_pass_onboarding) setShowPage("onboarding");
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log(err);
          setShowPage("auth-login");
        });
    } else {
      // setShowPage('auth-login')
    }
  }, []);

  useEffect(() => {
    if (showPage == "guided" || showPage == "askquestion") isInChatPageSet(true);
    else isInChatPageSet(false);
  }, [showPage]);

  useEffect(() => {
  }, [uuid]);

  const onUpdateQuestionToBeAsked = async (text) => {
    setQuestionToBeAsked(text);
  };

  const onChangeEvaluationAnswer = async (text) => {
    setEvaluationAnswer(text);
  };

  const handleShowModalOptions = async (isTrue) => {
    setIsShowModalOptions(isTrue);
  };

  const onChangeIsTE21 = async (isTrue) => {
    setIsTE21(isTrue);
  };

  const onChangeIsLearningResource = async (isTrue) => {
    setIsLearningResource(isTrue);
  };

  const onChangeIs5C = async (isTrue) => {
    setIs5C(isTrue);
  };

  const onChangeisNavigationComingFromCourseDesign = async (isTrue) => {
    setIsNavigationComingFromCourseDesign(isTrue);
  };

  const onChangeAnswerResult = async (contentInside) => {
    setAnswerResult(contentInside);
  };

  const onChangeLoadingState = async (isTrue) => {
    setLoading(isTrue);
  };

  const onChangeGuidedCoursePage = async (number) => {
    setGuidedCoursePage(number);
  };

  const onChangeTypeGeneration = (type) => {
    setTypeGeneration(type);
  };

  const textingFunc = (text) => {
    setIsiKonten(text);
  };

  const onPageChange = async (data) => {
    setShowPage(data);
  };

  const onChangeIsEvaluated = async (isTrue) => {
    setIsEvaluated(isTrue);
  };

  const submitChat = async () => {
    // insert open AI CODE HERE
    const ENDPOINT_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_Nie_Chatgpt";
    const ENDPOINT_MOE_URL = DEFAULT_AZURE_FUNCTION_URL + "/api/Alex_MOE_Chatgpt";
    let system_message_chat_conversation = overridePromp;

    const apiCode = "sILOvYHD3dTuriqF3qwihYOSZQKIzFPPytPJsMT9rqu6AzFu8zetWg==";
    const apiCodeMoe = "-zoGAGfr7T3zTkDGW59kv7JYA99pATN7MjxiCvgAz4T4AzFuc_i4cw==";
    const currentData = getStorage("currentLoggedInUser");
    const persona = currentData?.Persona;
    const tokenFinal = getStorage("tokenFinal");

    const requestPayload = {
      prompt: isiKonten,
      name: "tes", // Include the name you want in the request
      session_id: uuid, // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
      user_email: "test@nie.edu.sg",
    };

    if (persona === "NIE") {
      const response = await axios.post(ENDPOINT_URL + "?code=" + apiCode, requestPayload, {
        headers: {
          "x-functions-key": apiCode,
          Authorization: "Bearer " + tokenFinal,
        },
      });

      setChatResponse(response);
    } else {
      const response = await axios.post(ENDPOINT_MOE_URL + "?code=" + apiCodeMoe, requestPayload, {
        headers: {
          "x-functions-key": apiCodeMoe,
          Authorization: "Bearer " + tokenFinal,
        },
      });

      setChatResponse(response);
    }
  };

  const AFKCheck = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      let timeoutId;

      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setShowModal(true), 5 * 60 * 1000); // 5 minutes in milliseconds
      };

      const handleActivity = () => {
        resetTimeout();
        // setShowModal(false);
      };

      window.addEventListener("mousemove", handleActivity);
      window.addEventListener("keydown", handleActivity);

      resetTimeout();

      return () => {
        window.removeEventListener("mousemove", handleActivity);
        window.removeEventListener("keydown", handleActivity);
        clearTimeout(timeoutId);
      };
    }, []);

    return (
      <Modal show={showModal} centered backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="text-center">
            <h5>
              <b className="font-abadimt-probold text-default-blue">Hello, are you still with me?</b>
            </h5>
            <p className="font-abadimt-light text-default-blue font-md">
              I have not heard from you for the last 5 minutes. Are we all set to continue?{" "}
            </p>

            <div className="d-flex gap-3 justify-content-center font-abadimt-prolight">
              <div className="col text-end">
                <button className={"btn-custom-2 rounded-pill font-md py-2 px-2"} onClick={() => setShowModal(false)}>
                  Let's Continue
                </button>
              </div>
              <div className="col text-start">
                <button
                  className={"btn-custom-2 rounded-pill font-md py-2 px-2"}
                  onClick={() => {
                    removeStorage("currentLoggedInUser");
                    removeStorage("Office API client");
                    removeStorage("tokenFinal");
                    setShowPage("auth-login");
                  }}
                >
                  No, end session
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const OnResetSession = () => {
    // setUuid(Math.floor(Math.random() * 899999 + 100000).toString());
    setUuid(uuidv4());
  };

  const Router = () => {
    switch (showPage) {
      case "auth-login":
        return (
          <div className={"element"}>
            <Login routerHandler={onPageChange} />
          </div>
        );
      case "nav-custom-instruction":
        return (
          <div className={"element"}>
            <NavCustomInstruction routerHandler={onPageChange} />
          </div>
        );
      case "onboarding":
        return (
          <div className={"element"}>
            <OnBoarding routerHandler={onPageChange} />
          </div>
        );
      case "about":
        return (
          <div className={"element"}>
            <AboutUs routerHandler={onPageChange} />
          </div>
        );
      case "guided":
        return (
          <div className={"element"}>
            <GuidedStepOne
              currentPage={showPage}
              onPageChange={onPageChange}
              guidedCoursePage={guidedCoursePage}
              onChangeGuidedCoursePage={onChangeGuidedCoursePage}
              highlightedText={highlightedText}
              isNavigationComingFromCourseDesign={isNavigationComingFromCourseDesign}
              questionToBeAsked={questionToBeAsked}
              onUpdateQuestionToBeAsked={onUpdateQuestionToBeAsked}
              isTE21={isTE21}
              isLearningResource={isLearningResource}
              is5C={is5C}
              onChangeIsTE21={onChangeIsTE21}
              onChangeIsLearningResource={onChangeIsLearningResource}
              onChangeIs5C={onChangeIs5C}
              onChangeisNavigationComingFromCourseDesign={onChangeisNavigationComingFromCourseDesign}
              answerResult={answerResult}
              onChangeAnswerResult={onChangeAnswerResult}
              loading={loading}
              onChangeLoadingState={onChangeLoadingState}
              onChangeTypeGeneration={onChangeTypeGeneration}
              typeGeneration={typeGeneration}
              onChangeModalOptions={handleShowModalOptions}
              isShowModalOptions={isShowModalOptions}
              isEvaluated={isEvaluated}
              onChangeIsEvaluated={onChangeIsEvaluated}
              evaluationAnswer={evaluationAnswer}
              onChangeEvaluationAnswer={onChangeEvaluationAnswer}
              submitChat={submitChat}
              chatResponse={chatResponse}
              isiKonten={isiKonten}
              setIsiKonten={setIsiKonten}
              uuid={uuid}
              onResetSession={() => OnResetSession()}
            />
          </div>
        );

      case "askquestion":
        return (
          <div className={"element"}>
            <ChatStepTwo
              goToGuidedPage={onChangeGuidedCoursePage}
              handleClick={onPageChange}
              onChangeisNavigationComingFromCourseDesign={onChangeisNavigationComingFromCourseDesign}
              isNavigationComingFromCourseDesign={isNavigationComingFromCourseDesign}
              isTE21={isTE21}
              is5C={is5C}
              isLearningResource={isLearningResource}
              onChangeTypeGeneration={onChangeTypeGeneration}
              typeGeneration={typeGeneration}
              questionToBeAsked={questionToBeAsked}
              onChangeAnswerResult={onChangeAnswerResult}
              chatResponse={chatResponse}
              setIsiKonten={setIsiKonten}
              textingFunc={textingFunc}
              highlightedText={highlightedText}
              uuid={uuid}
              onResetSession={OnResetSession}
              // onSetIsTE21={onChangeIsTE21}
              // onSetIsLearningResource={onChangeIsLearningResource}
              // onSetIs5C={onChangeIs5C}
              // submitChat={handleSubmitChat}
              // isiKonten={isiKonten}
              // isiGuided1={isiGuided1}
              // resetChat={resetChat}
            />
          </div>
        );
      case "browseprompt":
        return (
          <div className={"element"}>
            <BrowsePrompt routeHandler={onPageChange} pageHandler={() => {}} />
          </div>
        );
      case "favouriteprompt":
        return (
          <div className={"element"}>
            <FavouritePrompt routeHandler={onPageChange} pageHandler={() => {}} />
          </div>
        );
      case "refinecourse":
        return (
          <div className={"element"}>
            <RefineCourse routeHandler={onPageChange} pageHandler={() => {}} />
          </div>
        );
      case "promptresult":
        return (
          <div className={"element"}>
            <PromptResult routeHandler={onPageChange} pageHandler={() => {}} />
          </div>
        );
      case "completeCustomInstruction":
        return (
          <div className={"element"}>
            <CompleteInstruction routerHandler={onPageChange} />
          </div>
        );
      default:
        return (
          <div className="bg-gray element">
            {/* <Header /> */}
            <Main currentPage={showPage} onPageChange={onPageChange} onGuidedPageChange={setGuidedCoursePage} />
          </div>
        );
    }
  };

  if (!isOfficeInitialized) {
    return (
      <Progress
        title={title}
        logo={require("./../../../assets/logo-filled.png")}
        message="Please sideload your addin to see app body."
      />
    );
  }

  return (
    // Page no need navbar or using navbar but inside component page not in root component
    showPage != "about" &&
      showPage != "onboarding" &&
      showPage != "auth-login" &&
      showPage != "completeCustomInstruction" && 
      showPage != "nav-custom-instruction" ? (
      // second condition, several page has p-4 from the start, but for new version need to remove p-4 from root div
      showPage != "" ? (
        <Navbar inChatPage={isInChatPage} showTooltip={isInChatPage} routerHandler={onPageChange}>
          {loading ? (
            <LoadingPage />
          ) : (
            <div className="pt-4 px-4">
              {/*{Router()}*/}
              <>
                {Router()}
                <AFKCheck />
              </>
            </div>
          )}
        </Navbar>
      ) : (
        <Navbar inChatPage={isInChatPage} showTooltip={isInChatPage} routerHandler={onPageChange}>
          {loading ? (
            <LoadingPage />
          ) : (
            <>
              <Router />
              <AFKCheck />
            </>
          )}
        </Navbar>
      )
    ) : (
      <>
        <Router />
        <AFKCheck />
      </>
    )
  );
}
