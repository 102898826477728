import React, { useEffect, useState } from "react";
import { CommandBar, ICommandBarItemProps } from "@fluentui/react/lib/CommandBar";
import { IButtonProps } from "@fluentui/react/lib/Button";
import OutsideClickHandler from "react-outside-click-handler";
import { getStorage, removeStorage, setStorage } from "../../helpers/utils";
import axios from "axios";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DEFAULT_AZURE_FUNCTION_URL, CMS_USER_LIST_CODE, STORE_USER_INFO_CODE } from "../../environment";

const overflowProps: IButtonProps = { ariaLabel: "More commands" };

// inChatPage is to make custom instruction disabled
export function Navbar({ children, routerHandler, inChatPage = false, showTooltip = false }) {
  const logo = require("../assets/alex-logo-cropped.png");
  const instructionIcon = require("../assets/fs-instruction.png");
  const logoutIcon = require("../assets/fs-logout.png");
  const privacyPreference = require("../assets/new-version/account-lock.png");
  const [dropdownNav, dropdownNavSet] = useState(false);
  const [showPrivacyModal, showPrivacyModalSet] = useState(false);

  const handleClick = (pathString) => {
    routerHandler(pathString);
  };

  const logout = () => {
    removeStorage("tokenFinal");
    removeStorage("currentLoggedInUser");
    handleClick("auth-login");
  };

  const updateIsConsent = (isConsent = false) => {
    const tokenFinal = getStorage("tokenFinal");
    const currentUserData = getStorage("currentLoggedInUser");
    if (tokenFinal) {
      axios
        .get("https://graph.microsoft.com/v1.0/me", {
          headers: {
            Authorization: "Bearer " + tokenFinal,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            axios
              .post(
                `${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_Store_User_Info?code=${STORE_USER_INFO_CODE}`,
                {
                  user_data: res.data,
                  persona: currentUserData.Persona,
                  custom_instruction: currentUserData.Custom_instruction,
                  is_pass_onboarding: currentUserData.is_pass_onboarding,
                  is_consent: isConsent,
                },
                {
                  headers: {
                    Authorization: "Bearer " + tokenFinal,
                  },
                }
              )
              .then((res2) => {
                setStorage("currentLoggedInUser", res2.data.user_information[0]);
              });
          }
        })
        .catch((err) => {
          console.error(err)
          removeStorage("currentLoggedInUser");
          removeStorage("Office API client");
          removeStorage("tokenFinal");
          removeStorage("bearerToken");
          window.location.reload();
        });
    }
  };

  return (
    <>
      <div className={`justify-content-between align-items-center py-3 ps-4 pe-3 border shadow-sm d-flex`}>
        <div>
          <img src={logo} className="img-fluid navbar-logo" alt="Alex Logo" />
        </div>
        <div
          className=""
          role="button"
          onClick={() => {
            dropdownNavSet(!dropdownNav);
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18" fill="none">
            <path
              d="M2 3C1.60218 3 1.22064 2.84196 0.93934 2.56066C0.658035 2.27936 0.5 1.89782 0.5 1.5C0.5 1.10218 0.658035 0.720644 0.93934 0.43934C1.22064 0.158035 1.60218 -8.29566e-08 2 -6.55671e-08C2.39782 -4.81776e-08 2.77936 0.158035 3.06066 0.43934C3.34196 0.720644 3.5 1.10218 3.5 1.5C3.5 1.89782 3.34196 2.27936 3.06066 2.56066C2.77936 2.84196 2.39782 3 2 3ZM2 10.5C1.60217 10.5 1.22064 10.342 0.93934 10.0607C0.658035 9.77935 0.5 9.39782 0.5 9C0.5 8.60218 0.658035 8.22064 0.93934 7.93934C1.22064 7.65804 1.60217 7.5 2 7.5C2.39782 7.5 2.77936 7.65804 3.06066 7.93934C3.34196 8.22064 3.5 8.60218 3.5 9C3.5 9.39782 3.34196 9.77936 3.06066 10.0607C2.77935 10.342 2.39782 10.5 2 10.5ZM2 18C1.60217 18 1.22064 17.842 0.939339 17.5607C0.658035 17.2794 0.499999 16.8978 0.499999 16.5C0.499999 16.1022 0.658035 15.7206 0.939339 15.4393C1.22064 15.158 1.60217 15 2 15C2.39782 15 2.77935 15.158 3.06066 15.4393C3.34196 15.7206 3.5 16.1022 3.5 16.5C3.5 16.8978 3.34196 17.2794 3.06066 17.5607C2.77935 17.842 2.39782 18 2 18Z"
              fill="#343644"
            />
          </svg>
        </div>
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          dropdownNav ? dropdownNavSet(false) : "";
        }}
      >
        <div className={`nav-dropdown-wrapper ${dropdownNav ? "" : "d-none"}`}>
          <div className="nav-dropdown">
            {inChatPage ? (
              showTooltip ? (
                <OverlayTrigger
                  overlay={
                    <Tooltip className="my-custom-tooltip-3 w-100" style={{ maxWidth: "65%" }} id="tooltip-2">
                      You can switch your role in the main menu. Remember to reset the conversation to access this
                      option.
                    </Tooltip>
                  }
                  placement="bottom"
                >
                  <div className="col-12 px-0 nav-dropdown-menu font-abadimt-light fw-light" onClick={() => {}}>
                    <div className="row mx-2 justify-content-between align-items-center">
                      <div className="col-auto">
                        <span className="text-inactive">Switch User Role</span>
                      </div>
                      <div className="col-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none">
                          <path
                            d="M1.5 6C0.95 6 0.5 5.55 0.5 5V2.5C0.5 1.95 0.95 1.5 1.5 1.5H5.5C6.05 1.5 6.5 1.95 6.5 2.5V5C6.5 5.55 6.05 6 5.5 6H4.5V7.5L3 6H1.5ZM10.5 9C11.05 9 11.5 8.55 11.5 8V5.5C11.5 4.95 11.05 4.5 10.5 4.5H7.5V5C7.5 6.1 6.6 7 5.5 7V8C5.5 8.55 5.95 9 6.5 9H7.5V10.5L9 9H10.5Z"
                            fill="#404040"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </OverlayTrigger>
              ) : (
                <div className="col-12 px-0 nav-dropdown-menu font-abadimt-light fw-light" onClick={() => {}}>
                  <div className="row mx-2 justify-content-between align-items-center">
                    <div className="col-auto">
                      <span className="text-inactive">Switch User Role</span>
                    </div>
                    <div className="col-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none">
                        <path
                          d="M1.5 6C0.95 6 0.5 5.55 0.5 5V2.5C0.5 1.95 0.95 1.5 1.5 1.5H5.5C6.05 1.5 6.5 1.95 6.5 2.5V5C6.5 5.55 6.05 6 5.5 6H4.5V7.5L3 6H1.5ZM10.5 9C11.05 9 11.5 8.55 11.5 8V5.5C11.5 4.95 11.05 4.5 10.5 4.5H7.5V5C7.5 6.1 6.6 7 5.5 7V8C5.5 8.55 5.95 9 6.5 9H7.5V10.5L9 9H10.5Z"
                          fill="#404040"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div
                className="col-12 px-0 nav-dropdown-menu font-abadimt-light fw-light"
                onClick={() => {
                  handleClick("nav-custom-instruction");
                  dropdownNavSet(false);
                }}
              >
                <div className="row mx-2 justify-content-between align-items-center">
                  <div className="col-auto">
                    <span className="text-default-blue">Switch User Role</span>
                  </div>
                  <div className="col-auto">
                    <img src={instructionIcon} alt="Instruction" />
                  </div>
                </div>
              </div>
            )}

            <div
              className="col-12 px-0 nav-dropdown-menu font-abadimt-light fw-light"
              onClick={() => {
                showPrivacyModalSet(true);
                dropdownNavSet(false);
              }}
            >
              <div className="row mx-2 justify-content-between align-items-center">
                <div className="col-auto">
                  <span className="text-default-blue">Privacy Preferences</span>
                </div>
                <div className="col-auto">
                  <img src={privacyPreference} alt="Privacy Preferences" />
                </div>
              </div>
            </div>
            {/* <div
              className="col-12 px-0 nav-dropdown-menu font-abadimt-light fw-light"
              onClick={() => {
                dropdownNavSet(false);
                removeStorage("currentLoggedInUser");
                removeStorage("Office API client");
                removeStorage("tokenFinal");
                handleClick("auth-login");
              }}
            >
              <div className="row mx-2 justify-content-between align-items-center">
                <div className="col-auto">
                  <span>Logout</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </OutsideClickHandler>
      <Modal show={showPrivacyModal} centered backdrop="static" keyboard={false} className="need-margin">
        <Modal.Body>
          <div className="text-end">
            <img
              src={require("../assets/new-version/x-icon.png")}
              width={20}
              alt="save changes"
              className="pe-1 cursor-pointer"
              onClick={() => showPrivacyModalSet(false)}
            />
          </div>
          <div className="text-center px-4">
            <h5>
              <b className="font-abadimt-probold text-default-blue font-lg">Permission for Interaction Tracking</b>
            </h5>
            <p className="font-abadimt-light text-default-blue font-md">
              Please let us know if you allow ALEX to record your interactions. This will be used for research purposes.
            </p>

            <div className="d-flex gap-3 justify-content-center font-abadimt-prolight mb-4">
              <div className="col text-end">
                <button
                  className={"btn-custom-2 rounded-pill font-abadimt-prolight font-md"}
                  onClick={() => {
                    showPrivacyModalSet(false);
                    updateIsConsent(false);
                  }}
                >
                  Decline
                </button>
              </div>
              <div className="col text-start">
                <button
                  className={"btn-custom-2 rounded-pill font-abadimt-prolight font-md"}
                  onClick={() => {
                    showPrivacyModalSet(false);
                    updateIsConsent(true);
                  }}
                >
                  Allow
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {children}
    </>
  );
}
