import React, { useEffect, useState } from "react";
import { getStorage, setStorage, removeStorage } from "../../helpers/utils";
import axios from "axios";
import Complete from "./onboarding/Complete";
import CompleteInstruction from "./CompleteInstruction";
import { DEFAULT_AZURE_FUNCTION_URL, CMS_USER_LIST_CODE, STORE_USER_INFO_CODE } from "../../environment";
import Modal from "react-bootstrap/Modal";
import { Navbar } from "./Navbar";

export default function NavCustomInstruction({ routerHandler }: { routerHandler: any }) {
  const [type, typeSet] = useState("");
  const [instruction, instructionSet] = useState("");

  const [oldType, oldTypeSet] = useState("");
  const [oldInstruction, oldInstructionSet] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showDone, showDoneSet] = useState(false);
  const [showCancelConfirm, showCancelConfirmSet] = useState(false);

  const handleClick = (pathString) => {
    routerHandler(pathString);
  };

  const updateInstruction = () => {
    const tokenFinal = getStorage("tokenFinal");
    const currentUserData = getStorage("currentLoggedInUser");

    if (tokenFinal) {
      axios
        .get("https://graph.microsoft.com/v1.0/me", {
          headers: {
            Authorization: "Bearer " + tokenFinal,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            axios
              .post(
                `${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_Store_User_Info?code=${STORE_USER_INFO_CODE}`,
                {
                  user_data: res.data,
                  persona: type,
                  custom_instruction: instruction,
                  is_pass_onboarding: currentUserData.is_pass_onboarding,
                  is_consent: currentUserData.is_consent,
                },
                {
                  headers: {
                    Authorization: "Bearer " + tokenFinal,
                  },
                }
              )
              .then((res2) => {
                setStorage("currentLoggedInUser", res2.data.user_information[0]);
                showDoneSet(true);

                setTimeout(() => {
                  showDoneSet(false);
                  handleClick("");
                }, 3000);
              });
          }
        })
        .catch((err) => {
          console.error(err)
          removeStorage("currentLoggedInUser");
          removeStorage("Office API client");
          removeStorage("tokenFinal");
          removeStorage("bearerToken");
          window.location.reload();
        });
    } else {
      removeStorage("currentLoggedInUser");
      removeStorage("Office API client");
      removeStorage("tokenFinal");
      removeStorage("bearerToken");
      window.location.reload();

    }
  };

  useEffect(() => {
    const currentUserData = getStorage("currentLoggedInUser");
    typeSet(currentUserData.Persona);
    oldTypeSet(currentUserData.Persona);
    instructionSet(currentUserData.Custom_instruction);
    oldInstructionSet(currentUserData.Custom_instruction);
  }, []);

  const cancelAction = () => {
    if (oldType != type || oldInstruction != instruction) {
      showCancelConfirmSet(true);
    } else {
      handleClick("");
    }
  };

  return (
    <Navbar inChatPage={true} routerHandler={handleClick}>
      <div className="pt-4 px-4">
        <div className="row text-default-blue justify-start align-items-center px-3 nav-custom-instruction">
          <div className="col-12 p-0">
            <h1 className="font-abadimt-probold font-lg mb-3">Switch User Role</h1>
            <p className="font-abadimt-pro font-md mb-3 font-purple">Who do you teach?</p>
          </div>
          <div className="col-12 p-0 teach-select mb-4">
            <label htmlFor="teach1" className="font-abadimt-extralight font-md mb-3">
              <input
                className="text-default-blue"
                type="radio"
                name="teach"
                value="NIE"
                id="teach1"
                checked={type == "NIE"}
                onChange={(e) => typeSet(e.target.value)}
              />{" "}
              <span className="font-abadimt-probold">I teach teachers</span>
            </label>
            <br />
            <label htmlFor="teach2" className="font-abadimt-extralight font-md">
              <input
                className="text-default-blue"
                type="radio"
                name="teach"
                value="MOE"
                id="teach2"
                checked={type == "MOE"}
                onChange={(e) => typeSet(e.target.value)}
              />{" "}
              <span className="font-abadimt-probold">I teach young learners</span>
            </label>
          </div>
          <div className="col-12 p-0">
            <p className="font-abadimt-pro font-md font-purple">
              What would you like me to know about, what you teach, and who your students are so we work better
              together?
            </p>
          </div>
          <div className="col-12 p-0 mb-5">
            <div className="card">
              <div className="card-body rounded">
                <textarea
                  placeholder="Write here"
                  className="bg-transparent text-default-gray"
                  name="instruction"
                  id="instruction"
                  cols={10}
                  rows={10}
                  onChange={(e) => instructionSet(e.target.value)}
                  value={instruction}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="col-12 text-center p-0">
            <button className="btn rounded-pill" style={{ marginRight: "10px" }} onClick={cancelAction}>
              {/* <img src={require("../assets/new-version/x-icon.png")} width={20} alt="save changes" className="pe-1" /> */}
              <span className="font-abadimt-extralight font-md px-2">Cancel</span>
            </button>
            <button className="btn rounded-pill" onClick={updateInstruction}>
              {/* <img src={require("../assets/right-arrow-icon.png")} width={20} alt="save changes" className="pe-1" /> */}
              <span className="font-abadimt-extralight font-md">Save Changes</span>
            </button>
          </div>
          <Modal show={showDone} centered backdrop="static" keyboard={false} className="need-margin">
            <Modal.Body>
              <div className="text-center">
                <h5>
                  <b className="font-abadimt-probold text-default-blue font-lg">Role Updated</b>
                </h5>
                <p className="font-abadimt-light text-default-blue font-md">Enjoy your personalised experience!</p>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={showCancelConfirm} centered backdrop="static" keyboard={false} className="need-margin">
            <Modal.Body>
              <div className="text-center">
                <h5>
                  <b className="font-abadimt-probold text-default-blue font-lg">Cancel Action?</b>
                </h5>
                <p className="font-abadimt-light text-default-blue font-md">
                  All changes have not been saved. Are you sure you want to cancel and discard these changes?
                </p>
              </div>
              <div className="d-flex gap-3 justify-content-center font-abadimt-prolight">
                <div className="col text-end">
                  <button
                    className={"btn-custom-2 rounded-pill font-abadimt-prolight font-md"}
                    onClick={() => showCancelConfirmSet(false)}
                  >
                    No
                  </button>
                </div>
                <div className="col text-start">
                  <button
                    className={"btn-custom-2 rounded-pill font-abadimt-prolight font-md"}
                    onClick={() => handleClick("")}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Navbar>
  );
}
