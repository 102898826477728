/* eslint-disable prettier/prettier */
import React, {useState} from "react";
import BackHome from "../utility/BackHome";
import Button from "../utility/Button";

const BrowsePrompt = ({ routeHandler, pageHandler }) => {

    const [option, setOption] = useState(1)
    const [highlight, setHighlight] = useState(1)

    const routeChange = (value) => {
        routeHandler(value);
    }

    const pageChange = (value) => {
        pageHandler(value);
    }

    return (
        <div className="p-2">
            <BackHome backFunction={() => pageChange(1)} routeHandler={routeChange}/>
            <div className="row justify-content-start items-align-center mx-0 mt-3 p-0">
                <h3 className="font-abadimt-extralight col-12 p-0 px-2 font-xl">Prompts</h3>
                <p onClick={() => {}} className="col-12 p-0 px-2 font-action-url font-md text-green-500 font-abadimt-extralight">What is ‘Prompt’?</p>
                <div className="col-12 p-0 px-2 my-3">
                    <div className="row items-align-center justify-start">
                        <div className="col-auto p-0 mb-2">
                            <Button onClick={() => {setOption(1)}} text="Lesson/Course Design" className={`text-rem bg-gray ${option === 1 ? 'active' : ''}`}/>
                        </div>
                        <div className="col-auto p-0 mb-2">
                            <Button onClick={() => {setOption(2)}} text="Pedagogical Frameworks" className={`text-rem bg-gray ${option === 2 ? 'active' : ''}`}/>
                        </div>
                        <div className="col-auto p-0 mb-2">
                            <Button onClick={() => {setOption(3)}} text="Educational Technology" className={`text-rem bg-gray ${option === 3 ? 'active' : ''}`}/>
                        </div>
                        <div className="col-auto p-0 mb-2">
                            <Button onClick={() => {setOption(4)}} text="Assessment Task" className={`text-rem bg-gray ${option === 4 ? 'active' : ''}`}/>
                        </div>
                        <div className="col-auto p-0 mb-2">
                            <Button onClick={() => {setOption(5)}} text="Rubrics" className={`text-rem bg-gray ${option === 5 ? 'active' : ''}`}/>
                        </div>
                        <div className="col-auto p-0 mb-2">
                            <Button onClick={() => {setOption(6)}} text="TLAs" className={`text-rem bg-gray ${option === 6 ? 'active' : ''}`}/>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 mb-3">
                    <div className={`card mx-0 default-card clickable ${highlight === 1 ? 'active' : ''}`} onClick={() => setHighlight(1)}>
                        <div className="card-body">
                            <p className="font-abadimt-probold">
                                <div className="row mx-0 justify-content-start align-items-start">
                                    <div className="col-auto p-0 me-1">
                                        <span className="font-abadimt-probold inline-block">Related category: </span>
                                    </div>
                                    <div className="col p-0 pe-1">
                                        <div className="row mx-0 justify-content-start align-items-start font-abadimt-probold">
                                            <div className="col-auto p-0 pe-1"><span className="font-highlight">Lesson/Course Design</span> </div>
                                            <div className="col-auto p-0 pe-1"><span className="font-highlight">Pedagogical Frameworks</span></div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <p className="font-abadimt-extralight">
                                Write me a <span className="font-action-hightlight">[number of lessons]</span> plan on topic for audience outlining the measurable and observerable intended learning outcomes. The intended learning outcomes to adhere to the ABCD model with the audience, behavior, condition and degree explicitly stated. The assessment tasks and activities are to be constructively aligned with the intended learning outcomes.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 mb-3">
                    <div className={`card mx-0 default-card clickable ${highlight === 2 ? 'active' : ''}`} onClick={() => setHighlight(2)}>
                        <div className="card-body">
                            <p className="font-abadimt-probold">
                                <div className="row mx-0 justify-content-start align-items-start">
                                    <div className="col-auto p-0 me-1">
                                        <span className="font-abadimt-probold inline-block">Related category: </span>
                                    </div>
                                    <div className="col p-0 pe-1">
                                        <div className="row mx-0 justify-content-start align-items-start font-abadimt-probold">
                                            <div className="col-auto p-0 pe-1"><span className="font-highlight">Lesson/Course Design</span> </div>
                                            <div className="col-auto p-0 pe-1"><span className="font-highlight">Pedagogical Frameworks</span></div>
                                            <div className="col-auto p-0 pe-1"><span className="font-highlight">Educational Technology</span></div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <p className="font-abadimt-extralight">
                                Write a <span className="font-action-hightlight">[number of lessons]</span> plan about topic in the format of Gagne's 9 events of instruction model. Incorporate learner-centered collaborative learning activities with the use of Google Workspace tools. Suggest formative and summative assessments to assess learning based on the proposed learning outcomes and activities.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 mb-3">
                    <div className={`card mx-0 default-card clickable ${highlight === 3 ? 'active' : ''}`} onClick={() => setHighlight(3)}>
                        <div className="card-body">
                            <p className="font-abadimt-probold">
                                <div className="row mx-0 justify-content-start align-items-start">
                                    <div className="col-auto p-0 me-1">
                                        <span className="font-abadimt-probold inline-block">Related category: </span>
                                    </div>
                                    <div className="col p-0 pe-1">
                                        <div className="row mx-0 justify-content-start align-items-start font-abadimt-probold">
                                            <div className="col-auto p-0 pe-1"><span className="font-highlight">Assessment Task</span> </div>
                                            <div className="col-auto p-0 pe-1"><span className="font-highlight">Educational Technology</span></div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <p className="font-abadimt-extralight">
                                Apart from using journals, recommend at least 5 creative, technology-enabled ways for learners to selfreflect.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 mb-3">
                    <div className={`card mx-0 default-card clickable ${highlight === 4 ? 'active' : ''}`} onClick={() => setHighlight(4)}>
                        <div className="card-body">
                            <p className="font-abadimt-probold">
                                <div className="row mx-0 justify-content-start align-items-start">
                                    <div className="col-auto p-0 me-1">
                                        <span className="font-abadimt-probold inline-block">Related category: </span>
                                    </div>
                                    <div className="col p-0 pe-1">
                                        <div className="row mx-0 justify-content-start align-items-start font-abadimt-probold">
                                            <div className="col-auto p-0 pe-1"><span className="font-highlight">Assessment Task</span> </div>
                                        </div>
                                    </div>
                                </div>
                            </p>
                            <p className="font-abadimt-extralight">
                                You are a teacher. Write only 2 reflection questions for your learners to reflect on what they have learnt about topic and how they will apply this method to other contexts. Also, include guidelines on how they should do this self-reflection.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 text-center mt-3">
                    <Button onClick={() => {}} text="Use Prompt" iconSize={15} icon={require("../../assets/fs-comment-box.png")}/>
                </div>
            </div>
        </div>
    )
}

export default BrowsePrompt;
