import * as React from "react";

export default function LoadingPage() { 
  const loadingImage = require('../assets/loading-nie-word.gif') 

  return (
    <div className="d-flex justify-content-center  margin-top-25vh" > 
      <img src={loadingImage} alt="loading" className="img-fluid  " />
    </div>
  )
}
