import App from "./components/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
/* global document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;
// let highlightedText = "";


const title = "Contoso Task Pane Add-in";
let initialText = "";
let highlightedText = ""

let selectionText = "";

const render = (Component) => {


  ReactDOM.render(
      <ThemeProvider>
        <Component title={title} isOfficeInitialized={isOfficeInitialized} highlightedText={highlightedText} />
      </ThemeProvider>
    ,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.onReady(() => {

  isOfficeInitialized = true;
  render(App)
}).catch(err => console.error(err));

function selectionChangedHandler() {
  try {
      return Word.run(async (context) => {
          // Get the current selection in the Word document
          const range = context.document.getSelection();

          // Load the text of the selection
          range.load("text");

          // Sync the document state and get the selection text
          await context.sync();

          // Update the state with the selected text

          highlightedText = range.text;
          return (range.text);

      });
  } catch (error) {
      console.error(error);
      return error
  }
}


if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
