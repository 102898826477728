const DEFAULT_AZURE_FUNCTION_URL="https://alex-nie-functions-prod.azurewebsites.net"

const STORE_USER_INFO_CODE="g3xp0kR9SAiiFVZkqvvtRd31vB04cdjH1coFY9lWWR1jAzFug1AX5Q=="
const CMS_USER_LIST_CODE="FbrgbkxAsfrHhwSD7vNs8Z8BYJyPiL9yo_0lxwQHXHCJAzFuWAbe4g=="
const PROMPT_CATEGORY_CODE = "pi6e_jprLVrHSQYYSaAYMV0f39_gCwHMfwU7dzsfgQ5cAzFufPyCpQ=="
const BROWSE_PROMPT_CODE = "_AydZZTCZQ-XPzzjsVO9-BR_VbvDEnITA7hIwH39FdbZAzFu7Me5Sw=="
const FAVORITE_PROMPT_CODE = "F0xBm3IC0tF2ruH_Kc34l8lxgmbtALEB0SdK4idfjUG0AzFuecmqZg=="

const ALEX_ACCESS_TOKEN_CODE = "A013oUzmr1KKl0fYEVNlJScIixrX_GUu2wt-YpE_puKZAzFu8pCOBw=="
const LOGIN_CHECK_NIE_CODE = "ApiSoXzE-zJfWecthDZIot44Lb54LqEqE-CGc8mQifPXAzFuU9WOtQ=="

const ALEX_NIE_COURSE_CODE = "TWSC2Xq4e-ayLmiu_tZaTwN0osrpNTzR8FSyYS03JliZAzFunD38Hw=="
const ALEX_MOE_LESSON_CODE = "hA0wXpFCm8bqRyHHEZhd11dDWu-NIvG6ajhbzt8Tl5ACAzFufUjarQ=="
const ALEX_REFINEMENT_INQUIRY_CODE = "eN-uKRl9D9XXX9MthzVfSFbC9HGpFXX2TXNZBhOSEoMHAzFuXXMB7g=="
const ALEX_CONVERSATIONAL_CHAT_CODE = "WYqQjJ1sW1Wp1IRSXeua_NFyONLMIKCxrIaBZ7RF70C5AzFunnA5pg=="

const CLIENT_ID = "5fe1ae44-1693-412b-8e3f-088e62fb6775"
const CLIENT_SECRET = "tC18Q~NwpclaEI1Mn12SlEfmgGgI1Drg0eEB_aOm"
const REDIRECT_URL = "https://alex-add-in.nie.sg/fallbackauthdialog.html"

// const CLIENT_ID = "5e922a4e-c2d4-4881-8796-135c30ba5ca4"; // LOCAL
// const CLIENT_SECRET = "VFi8Q~3Q5Y2.N1Hw-MQCFfdhmd1IPfiGr~DNea.m";
// const REDIRECT_URL = "https://niehostword.deeeplabs.com/fallbackauthdialog.html"

// const CLIENT_ID = "8331f048-3621-4d22-a3ca-183db9d4a75c"; // OLD
// const CLIENT_SECRET = "eVK8Q~qBEoOgGHYkag8BMcgm.hZsBkT2rAgaMae_";
// const REDIRECT_URL = "https://niehostword.deeeplabs.com/fallbackauthdialog.html"

export {
  DEFAULT_AZURE_FUNCTION_URL,
  STORE_USER_INFO_CODE,
  CMS_USER_LIST_CODE,
  PROMPT_CATEGORY_CODE,
  BROWSE_PROMPT_CODE,
  FAVORITE_PROMPT_CODE,
  CLIENT_ID,
  CLIENT_SECRET,
  ALEX_ACCESS_TOKEN_CODE,
  LOGIN_CHECK_NIE_CODE,
  ALEX_NIE_COURSE_CODE,
  ALEX_MOE_LESSON_CODE,
  ALEX_REFINEMENT_INQUIRY_CODE,
  ALEX_CONVERSATIONAL_CHAT_CODE,
  REDIRECT_URL
}