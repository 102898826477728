/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import Greeting from "./Greeting";
import SetupPersona from "./SetupPersona";
import WritingBackground from "./WritingBackground";
import Complete from "./Complete";
import Start from "./Start";

export default function OnBoarding({ routerHandler }) {
    const [page, pageSet] = useState("greeting");

    const onBoardingPageSet = (value) => {
        pageSet(value);
    }

    const changeRootPage = (value) => {
        routerHandler(value);
    }

    const Page = () => {
        switch (page) {
            case "persona":
                return <SetupPersona routerHandler={changeRootPage} onBoardingPageChange={onBoardingPageSet}/>
            case "background":
                return <WritingBackground routerHandler={changeRootPage} onBoardingPageChange={onBoardingPageSet}/>
            case "start":
                return <Complete routerHandler={changeRootPage} onBoardingPageChange={onBoardingPageSet}/>
            case "skipstart":
                return <Start routerHandler={changeRootPage} onBoardingPageChange={onBoardingPageSet}/>
            default:
                return <Greeting routerHandler={changeRootPage} onBoardingPageChange={onBoardingPageSet}/>
        }
    }

    return (
        <Page />
    )
}