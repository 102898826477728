/* eslint-disable prettier/prettier */
import React from "react";
import BackHome from "../utility/BackHome";

const RefineCourse = ({ routeHandler, pageHandler, isRegenerate = false, previousPromptValue = "" }) => {
    const routeChange = (value) => {
        routeHandler(value);
    }

    const pageChange = (value) => {
        pageHandler(value);
    }

    return (
        <div className="p-2">
            <BackHome backFunction={() => pageChange("")} routeHandler={routeChange}/>
            <div className="row justify-content-start align-items-center mx-0 mt-3 p-0">
                <h3 className="font-abadimt-extralight col-12 p-0 px-2 font-lg">Refine a Course Design</h3>
                <p className="col-12 p-0 px-2 font-md text-green-500 font-abadimt-procondensed">
                    Refine by highlighting, or ask questions straight away by prompting. 
                </p>
                <div className="col-12 p-0 mb-3">
                    <div className="card mx-0 default-card">
                        <div className="card-body p-2">
                            <p className="font-abadimt-extralight m-0">
                                Online quizzes, reflective writing assignments, peer feedback on food memoirs
                            </p>
                        </div>
                    </div>
                </div>
                <p className="col-12 p-0 px-2 font-md text-green-500 font-abadimt-procondensed mb-0">
                    Write down your prompt
                </p>
                <div className="col-12 p-0 px-2 mb-3">
                    <div className="row mx-0 justify-content-start align-items-center">
                        <div className="col-auto p-0 pe-2">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto p-0">
                                    <img src={require("../../assets/fs-comment-box.png")} width={15} alt="Browse Prompt" className="d-inline-block me-1" />
                                </div>
                                <div className="col-auto p-0">
                                    <span className="font-abadimt-extralight font-sm font-action-url-purple">Browse prompts</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto p-0">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto p-0">
                                    <img src={require("../../assets/fs-browse-favorites.png")} width={15} alt="Browse Prompt" className="d-inline-block me-1" />
                                </div>
                                <div className="col-auto p-0">
                                    <span className="font-abadimt-extralight font-sm font-action-url-purple">{!isRegenerate ? "Browse favourite prompts" : "..and write down your follow up prompt"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0">
                    <div className="row mx-0">
                        <p className="col-12 p-0 px-2 font-md text-green-500 font-abadimt-procondensed mb-2">
                            {!isRegenerate ? "Please help to suggest alternatives to the ATs highlighted for week 1 that are more collaborative in nature."
                            : previousPromptValue}
                        </p>
                        <div className="col-12 p-0 px-2 overflow-x-hidden">
                            <div className="row mx-0 align-items-center justify-content-start">
                                <div className="col p-0">
                                    <textarea
                                        name="input"
                                        id="input"
                                        style={{ marginBottom: "-10px" }}
                                        defaultValue={""}
                                    />
                                </div>
                                <div className="col-1 p-0">
                                    <button className="p-0 m-0 bg-transparent border-0 " onClick={() => {}}>
                                    <img src={require('../../assets/fs-next-arrow.png')} alt="Next " width={16} />
                                    </button>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RefineCourse;