/* eslint-disable prettier/prettier */
import React from "react";

const BackHome = ({ backFunction, routeHandler }) => {

    // pass function to change state from app.tsx to this component
    const routeChangeHandler = (value) => {
        routeHandler(value);
    }

    return (
        <div className="d-flex justify-content-between me-3">
            <a href="#" className="back-button grey font-abadimt-extralight"
                onClick={backFunction}>
                <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} viewBox="0 0 3 5"
                        fill="none">
                    <path
                        d="M2.45301 4.16661C2.40811 4.16643 2.3637 4.15719 2.32246 4.13944C2.28122 4.12168 2.24399 4.09577 2.21301 4.06327L0.92634 2.72994C0.865265 2.66763 0.831055 2.58386 0.831055 2.49661C0.831055 2.40936 0.865265 2.32558 0.92634 2.26327L2.25967 0.92994C2.29075 0.89886 2.32765 0.874207 2.36826 0.857387C2.40886 0.840566 2.45239 0.831909 2.49634 0.831909C2.54029 0.831909 2.58382 0.840566 2.62442 0.857387C2.66503 0.874207 2.70193 0.89886 2.73301 0.92994C2.76409 0.961019 2.78874 0.997916 2.80556 1.03852C2.82238 1.07913 2.83104 1.12265 2.83104 1.16661C2.83104 1.21056 2.82238 1.25408 2.80556 1.29469C2.78874 1.3353 2.76409 1.37219 2.73301 1.40327L1.63301 2.49994L2.69301 3.59994C2.75509 3.66239 2.78994 3.74688 2.78994 3.83494C2.78994 3.923 2.75509 4.00748 2.69301 4.06994C2.66147 4.10123 2.62398 4.12591 2.58277 4.14251C2.54155 4.15911 2.49743 4.1673 2.45301 4.16661Z"
                        fill="#ABA6A6"
                    />
                </svg>
                Back
            </a>
            <a href="#" className="back-button grey" onClick={() => routeChangeHandler("")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 10 11"
                        fill="none">
                    <path
                        d="M10 4.5L5 0.5L0 4.5V5.5H1V10.5H3.5V8.5C3.5 8.10218 3.65804 7.72064 3.93934 7.43934C4.22064 7.15804 4.60218 7 5 7C5.39782 7 5.77936 7.15804 6.06066 7.43934C6.34196 7.72064 6.5 8.10218 6.5 8.5V10.5H9V5.5H10V4.5Z"
                        fill="#C8C5C5"
                    />
                </svg>
            </a>
        </div>
    )
}

export default BackHome;