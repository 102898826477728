/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useMemo, useState } from "react";
import Button from "../utility/Button";
import { Navbar } from "../Navbar";
import axios from "axios";
import { getStorage, setStorage, removeStorage } from "../../../helpers/utils";

import {
    DEFAULT_AZURE_FUNCTION_URL,
    STORE_USER_INFO_CODE,
    CMS_USER_LIST_CODE
  } from '../../../environment'

export default function SetupPersona({ routerHandler, onBoardingPageChange }) {
    const pageChange = (value) => {
        onBoardingPageChange(value);
    }

    const routerChange = (value) => {
        routerHandler(value);
    }

    const updatePersona = (choosenPersona: string) => {
        const tokenFinal = getStorage('tokenFinal');
        if (tokenFinal) {
            axios
                .get("https://graph.microsoft.com/v1.0/me", {
                    headers: {
                        Authorization: "Bearer " + tokenFinal,
                    },
                }).then((res) => {
                    if (res.status == 200) {
                        axios
                            .post(
                                `${DEFAULT_AZURE_FUNCTION_URL}/api/CMS_Store_User_Info?code=${STORE_USER_INFO_CODE}`,
                                {
                                    user_data: res.data,
                                    persona: choosenPersona,
                                    // custom_instruction: "",
                                    is_pass_onboarding: false,
                                },
                                {
                                    headers: {
                                        "Authorization": "Bearer" + tokenFinal
                                    }
                                }
                            )
                            .then((res2) => {
                                setStorage("currentLoggedInUser", res2.data.user_information[0]);
                                pageChange("background");
                            });
                    }
                })
                .catch((err) => {
                    removeStorage("currentLoggedInUser");
                    removeStorage("Office API client");
                    removeStorage("tokenFinal");
                    removeStorage("bearerToken");
                    window.location.reload();
                    console.error(err);
                });
        }
    }

    return (
        <Navbar routerHandler={routerChange} inChatPage={true}>
            <div className="greeting row mx-0 justify-center text-center overflow-x-hidden">
                <div className="col-12 p-0 my-auto">
                    <h3 className="font-abadimt-probold font-lg mb-4 text-default-blue">Set up your persona</h3>
                    <p className="font-abadimt-light font-md mb-4 text-default-blue px-4">
                        To personalise your experience, I'd love to know more about you. Please choose the role that best describes you
                    </p>
                </div>
                <div className="col-12 p-0 mb-3">
                    <div className="row mx-0 justify-center align-middle px-3">
                        <div className="col-6 p-0">
                            <Button className="font-sm text-default-blue font-abadimt-prolight active-persona persona-choice" text="I teach teachers" onClick={() => {
                                updatePersona("NIE");
                            }} />
                        </div>
                        <div className="col-6 p-0">
                            <Button className="font-sm text-default-blue font-abadimt-prolight persona-choice" text="I teach young learners" onClick={() => {
                                updatePersona("MOE");
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </Navbar>
    )
}
