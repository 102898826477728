/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from "react";
import { getStorage } from "../../helpers/utils";

export default function AboutUs({ routerHandler }) {
    const pageChange = () => {
        const userData = getStorage("currentLoggedInUser");

        if (userData && userData.is_pass_onboarding) routerHandler("")
        else if (userData && !userData.is_pass_onboarding) routerHandler("onboarding")
        else routerHandler("auth-login")
    }

    return (
        <div className="about-us row mx-0 p-4">
            <div className="col-12 p-0 text-start mb-4">
                <a href="#" className="back-button grey font-abadimt-extralight" onClick={() => pageChange()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} viewBox="0 0 3 5" fill="none">
                        <path
                            d="M2.45301 4.16661C2.40811 4.16643 2.3637 4.15719 2.32246 4.13944C2.28122 4.12168 2.24399 4.09577 2.21301 4.06327L0.92634 2.72994C0.865265 2.66763 0.831055 2.58386 0.831055 2.49661C0.831055 2.40936 0.865265 2.32558 0.92634 2.26327L2.25967 0.92994C2.29075 0.89886 2.32765 0.874207 2.36826 0.857387C2.40886 0.840566 2.45239 0.831909 2.49634 0.831909C2.54029 0.831909 2.58382 0.840566 2.62442 0.857387C2.66503 0.874207 2.70193 0.89886 2.73301 0.92994C2.76409 0.961019 2.78874 0.997916 2.80556 1.03852C2.82238 1.07913 2.83104 1.12265 2.83104 1.16661C2.83104 1.21056 2.82238 1.25408 2.80556 1.29469C2.78874 1.3353 2.76409 1.37219 2.73301 1.40327L1.63301 2.49994L2.69301 3.59994C2.75509 3.66239 2.78994 3.74688 2.78994 3.83494C2.78994 3.923 2.75509 4.00748 2.69301 4.06994C2.66147 4.10123 2.62398 4.12591 2.58277 4.14251C2.54155 4.15911 2.49743 4.1673 2.45301 4.16661Z"
                            fill="#ABA6A6"
                        />
                    </svg>
                    Back
                </a>
            </div>
            <div className="col-12 p-0">
                <div className="row mx-0 px-4 justify-center">
                    <div className="col-12 p-0 text-center mb-4">
                        <img src={require('../assets/alex-logo-with-text.png')} width={150} alt="Logo" />
                    </div>
                    <div className="col-12 p-0">
                        <p className="font-abadimt-light font-md text-default-blue">
                            ALEX is a product developed by the National Institute of Education, Singapore, with the primary goal of assisting in generating constructively aligned blended courses and lesson designs through human-AI collaboration. ALEX is meticulously designed to produce content that is finely tuned to the specific requirements and contexts of NIE and, potentially, MOE. <br /><br />
                            
                            ALEX is dedicated to transparency, revealing its inner workings and citing sources to empower users to make informed decisions. It actively encourages co-design and collaboration, recognising the vital role users play in this process. <br /><br />

                            While ALEX, a hybrid intelligence application, aspires to be a valuable resource for teacher educators, student teachers, and experienced educators, and potentially for their students, it is important for users to apply critical thinking and verify the output, especially in the context of its use.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}