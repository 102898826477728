/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React from "react";

export default function Button({ onClick, icon = "", iconSize = 25, text = "Text", className = "" }) {
    return (
        <button className={`utility-component btn rounded-pill ${className.length > 0 ? className : 'font-md'}`} onClick={onClick}>
            {icon.length > 1 ? <img src={icon} width={iconSize} alt="save changes" className="pe-1" /> : <></>}
            <span className={className.includes('font-abadimt') ? '' : 'font-abadimt-extralight'}>{text}</span>
        </button>
    );
}
